import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import * as validatorsBurby from '../../util/validatorsBurby';
import {
  ExternalLink,
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldSelect,
  FieldCheckboxBoolean,
} from '../../components';

import css from './ConfirmSignupForm.module.css';

import {
  USER_ACCOUNT_PERSONAL,
  USER_ACCOUNT_PROFESSIONAL,
} from '../../util/constantsBurby';

const KEY_CODE_ENTER = 13;

const ConfirmSignupFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenLegalAdvice,
        authInfo,
        idp,
        values,
      } = formRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      // terms checkbox
      const termsRequiredMessage = intl.formatMessage({
        id: 'SignupForm.termsRequired',
      });
      const termsRequired = validatorsBurby.requiredCheckbox(termsRequiredMessage);

      // instagram
      const instagramLabel = intl.formatMessage({
        id: 'SignupForm.instagramLabel',
      });
      const instagramPlaceholder = intl.formatMessage({
        id: 'SignupForm.instagramPlaceholder',
      });

      // account type
      const atData = {
        label: intl.formatMessage({
          id: 'SignupForm.accountTypeLabel',
        }),
        options: {
          none: {
            value: '',
            label: intl.formatMessage({
              id: 'SignupForm.noAccountType',
            })
          },
          personal: {
            value: USER_ACCOUNT_PERSONAL,
            label: intl.formatMessage({
              id: 'SignupForm.personalAccount',
            })
          },
          professional: {
            value: USER_ACCOUNT_PROFESSIONAL,
            label: intl.formatMessage({
              id: 'SignupForm.professionalAccount',
            })
          },
        },
      }

      const atRequiredMessage = intl.formatMessage({
        id: 'SignupForm.accountTypeRequired',
      });
      const atRequired = validators.required(atRequiredMessage);
      const atOptions = [];

      for(const opt in atData.options){
        atOptions.push(
          <option key={opt} value={atData.options[opt].value}>
            {atData.options[opt].label}
          </option>
        );
      }

      // idDocument
      const idDocumentLabel = intl.formatMessage({
        id: 'SignupForm.idDocumentLabel',
      });
      const idDocumentPlaceholder = intl.formatMessage({
        id: 'SignupForm.idDocumentPlaceholder',
      });
      const idDocumentRequiredMessage = intl.formatMessage({
        id: 'SignupForm.idDocumentRequiredMessage',
      });
      const idDocumentRequired = validators.required(idDocumentRequiredMessage);

      const renderIdDocumentFieldTextInput = values => {
        let output = '';

        if(values.accountType === 'professional'){
          output = (
            <div className="professional">
              <FieldTextInput
                className={css.idDocument}
                type="text"
                id={formId ? `${formId}.idDocument` : 'idDocument'}
                name="idDocument"
                autoComplete="off"
                label={idDocumentLabel}
                placeholder={idDocumentPlaceholder}
                validate={idDocumentRequired}
              />
            </div>
          )
        }

        return output;
      }

      const legalAdviceLink = (
        <ExternalLink
          className={css.termsLink}
          href="aviso-legal"
          target="_blank"
        >
          <FormattedMessage id="ConfirmSignupForm.legalAdviceLinkText" />
        </ExternalLink>
      );

      // If authInfo is not available we should not show the ConfirmForm
      if (!authInfo) {
        return;
      }

      // Initial values from idp provider
      const { email, firstName, lastName } = authInfo;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              initialValue={email}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="firstName"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                initialValue={firstName}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lastName` : 'lastName'}
                name="lastName"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                initialValue={lastName}
                validate={lastNameRequired}
              />
            </div>
          </div>
          <FieldTextInput
            className={css.instagram}
            type="text"
            id={formId ? `${formId}.instagram` : 'instagram'}
            name="instagram"
            autoComplete="nickname"
            label={instagramLabel}
            placeholder={instagramPlaceholder}
          />
          <FieldSelect
             className={css.accounType}
             id={formId ? `${formId}.accountType` : 'accountType'}
             name="accountType"
             label={atData.label}
             validate={atRequired}
          >
            {atOptions}
          </FieldSelect>
          {renderIdDocumentFieldTextInput(values)}
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FieldCheckboxBoolean
                  id={formId ? `${formId}.termsCheckbox` : 'termsCheckbox'}
                  textClassName={css.termsText}
                  name="termsCheckbox"
                  label={
                    <FormattedMessage
                      id="ConfirmSignupForm.termsAcceptText"
                      values={{ legalAdviceLink }}
                    />
                  }
                  validate={termsRequired}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="ConfirmSignupForm.signUp" values={{ idp: idp }} />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ConfirmSignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

ConfirmSignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenLegalAdvice: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ConfirmSignupForm = compose(injectIntl)(ConfirmSignupFormComponent);
ConfirmSignupForm.displayName = 'ConfirmSignupForm';

export default ConfirmSignupForm;
