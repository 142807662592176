import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Button,
  NamedLink,
} from '../../components';
import config from '../../config';
import { formatMoney } from '../../util/currency';

import css from './SectionContact.module.css';
import LogoImage from '../../assets/sports-icons/icon-bookmark.svg';

import CardImage from '../../assets/cards-pp.png';
import BeseifImage from '../../assets/Beseif-logo-pp.png';
    
const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const SectionContact = props => {
  const {
    intl,
    price,
    onContactUser,
    showContactUser,
    id,
    slug,
    publicData,
    ...rest
  } = props;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const reserved = publicData.reserved ? (
      <>
        <span className={css.a}>
          <img src={LogoImage} alt="" {...rest} />
          Artículo reservado
        </span>
      </>
    ) : (
      <>
        <NamedLink className={css.buyButton} name="PayPage" params={{ id, slug }}>
          Comprar ahora
        </NamedLink>
        <br />
        <div className={css.beseif}>
          <span style={{ display: "flex", alignItems: "center" }}>Pago 100% seguro <img src={CardImage} /></span>
          <br />
          <span style={{ display: "flex", alignItems: "center" }}>Powered by <img style={{ marginLeft: "10px" }} src={BeseifImage} /></span>
        </div>
      </>
    );

  const contact = (showContactUser) && (
    <>
      <Button
        rootClassName={css.contactButton}
        onClick={onContactUser}
      >
        <FormattedMessage id="SectionContact.buttonText" />
      </Button>
      <br />
      {reserved}
    </>
  );

  
  return (
    <div className={css.container}>
      <div className={css.sidebarContactForm}>
        <p className={css.contactIntro}>
          <FormattedMessage id="SectionContact.opportunityText" />
        </p>
        { contact }
      </div>
      <div className={css.stickyContactForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
        </div>
        { contact }
      </div>
    </div>
  );
}

export default SectionContact;
