/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
import iconBicycle from './assets/sports-icons/bicycle.svg';
import iconBoxing from './assets/sports-icons/boxing.svg';
import iconFitness from './assets/sports-icons/fitness.svg';
import iconDiving from './assets/sports-icons/diving.svg';
import iconAdaptedSport from './assets/sports-icons/adapted-sport.svg';
import iconClimbing from './assets/sports-icons/climbing.svg';
import iconGolf from './assets/sports-icons/golf.svg';
import iconKayak from './assets/sports-icons/kayak.svg';
import iconKitesurf from './assets/sports-icons/kitesurf.svg';
import iconRacketSports from './assets/sports-icons/racket-sports.svg';
import iconPaddleSurf from './assets/sports-icons/paddle-surf.svg';
import iconRunning from './assets/sports-icons/running.svg';
import iconSki from './assets/sports-icons/ski.svg';
import iconSnowboard from './assets/sports-icons/snowboard.svg';
import iconSurf from './assets/sports-icons/surf.svg';
import iconWindsurf from './assets/sports-icons/windsurf.svg';
import iconTech from './assets/sports-icons/tech.svg';
import iconOtherSports from './assets/sports-icons/other-sports.svg';

import {
  USER_ACCOUNT_PERSONAL,
  USER_ACCOUNT_PROFESSIONAL,
} from './util/constantsBurby';

export const filters = [{
    id: 'sport',
    label: 'Deporte',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_sport'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'ciclismo-es', label: 'Ciclismo', icon: iconBicycle },
        { key: 'boxeo-kickboxing-es', label: 'Boxeo & Kickboxing', icon: iconBoxing },
        { key: 'fitness-es', label: 'Fitness', icon: iconFitness },
        { key: 'buceo-snorkel-es', label: 'Buceo y Snorkel', icon: iconDiving },
        { key: 'deporte-adaptado-es', label: 'Deporte Adaptado', icon: iconAdaptedSport },
        { key: 'escalada-es', label: 'Escalada', icon: iconClimbing },
        { key: 'golf-es', label: 'Golf', icon: iconGolf },
        { key: 'kayak-es', label: 'Kayak', icon: iconKayak },
        { key: 'kitesurf-es', label: 'Kitesurf', icon: iconKitesurf },
        { key: 'deportes-raqueta-es', label: 'Deportes Raqueta', icon: iconRacketSports },
        { key: 'paddle-surf-es', label: 'Paddle Surf', icon: iconPaddleSurf },
        { key: 'running-es', label: 'Running', icon: iconRunning },
        { key: 'ski-es', label: 'Ski', icon: iconSki },
        { key: 'snowboard-es', label: 'Snowboard', icon: iconSnowboard },
        { key: 'surf-es', label: 'Surf', icon: iconSurf },
        { key: 'windsurf-es', label: 'Windsurf', icon: iconWindsurf },
        { key: 'tech-es', label: 'Tech', icon: iconTech },
        { key: 'other-sports-es', label: 'Otros Deportes', icon: iconOtherSports },
      ],
    },
  },
  {
    id: 'category',
    label: 'Categoría',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      options: [

        /** Ciclismo **/

        { key: 'bicicletas-es', label: 'Bicicletas', sport: 'ciclismo-es' },
        { key: 'componentes-es', label: 'Componentes', sport: 'ciclismo-es' },
        { key: 'cuadros-es', label: 'Cuadros', sport: 'ciclismo-es' },
        { key: 'ruedas-es', label: 'Ruedas', sport: 'ciclismo-es' },
        { key: 'protecciones-001-es', label: 'Protecciones', sport: 'ciclismo-es' },
        { key: 'ropa-001-es', label: 'Ropa', sport: 'ciclismo-es' },
        { key: 'bolsas-fundas-es', label: 'Bolsas y Fundas', sport: 'ciclismo-es' },
        { key: 'electronica-001-es', label: 'Electrónica', sport: 'ciclismo-es' },
        { key: 'transporte-es', label: 'Transporte', sport: 'ciclismo-es' },


        /** Boxeo Kickboxing **/

        { key: 'equipamiento-002-es', label: 'Equipamiento', sport: 'boxeo-kickboxing-es' },
        { key: 'ropa-calzado-es', label: 'Ropa y Calzado', sport: 'boxeo-kickboxing-es' },
        { key: 'protecciones-002-es', label: 'Protecciones', sport: 'boxeo-kickboxing-es' },
        { key: 'electronica-002-es', label: 'Electrónica', sport: 'boxeo-kickboxing-es' },


        /** Fitness **/

        { key: 'maquinas-gimnasio-es', label: 'Maquinas Gimnasio', sport: 'fitness-es' },
        { key: 'peso-libre-es', label: 'Peso Libre', sport: 'fitness-es' },
        { key: 'entrenamiento-funcional-es', label: 'Entrenamiento Funcional', sport: 'fitness-es' },
        { key: 'ropa-003-es', label: 'Ropa', sport: 'fitness-es' },
        { key: 'calzado-003-es', label: 'Calzado', sport: 'fitness-es' },
        { key: 'accesorios-003-es', label: 'Accesorios', sport: 'fitness-es' },
        { key: 'electronica-003-es', label: 'Electrónica', sport: 'fitness-es' },
        { key: 'salud-nutricion-es', label: 'Salud y Nutrición', sport: 'fitness-es' },


        /** Buceo Snorkel **/

        { key: 'equipamiento-004-es', label: 'Equipamiento', sport: 'buceo-snorkel-es' },
        { key: 'botellas-instrumentos-es', label: 'Botellas e Instrumentos', sport: 'buceo-snorkel-es' },
        { key: 'neopreno-es', label: 'Neopreno', sport: 'buceo-snorkel-es' },
        { key: 'accesorios-004-es', label: 'Accesorios', sport: 'buceo-snorkel-es' },
        { key: 'electronica-004-es', label: 'Electrónica', sport: 'buceo-snorkel-es' },
        { key: 'seguridad-004-es', label: 'Seguridad', sport: 'buceo-snorkel-es' },


        /** Deporte adaptado **/

        { key: 'handbike-bicicletas-adaptadas-es', label: 'Handbike y bicicletas adaptadas', sport: 'deporte-adaptado-es' },
        { key: 'silla-de-ruedas-es', label: 'Silla de Ruedas', sport: 'deporte-adaptado-es' },
        { key: 'fitness-005-es', label: 'Fitness', sport: 'deporte-adaptado-es' },
        { key: 'deportes-de-agua-es', label: 'Deportes de Agua', sport: 'deporte-adaptado-es' },


        /** Escalada **/

        { key: 'calzado-006-es', label: 'Calzado', sport: 'escalada-es' },
        { key: 'casco-arneses-es', label: 'Casco y Arneses', sport: 'escalada-es' },
        { key: 'cuerdas-cintas-es', label: 'Cuerdas y Cintas', sport: 'escalada-es' },
        { key: 'ferreteria-es', label: 'Ferretería', sport: 'escalada-es' },
        { key: 'nieve-hielo-es', label: 'Nieve y Hielo', sport: 'escalada-es' },
        { key: 'esenciales-es', label: 'Esenciales', sport: 'escalada-es' },
        { key: 'electronica-006-es', label: 'Electrónica', sport: 'escalada-es' },


        /** Golf **/

        { key: 'palos-es', label: 'Palos', sport: 'golf-es' },
        { key: 'accesorios-007-es', label: 'Accesorios', sport: 'golf-es' },
        { key: 'calzado-007-es', label: 'Calzado', sport: 'golf-es' },
        { key: 'ropa-007-es', label: 'Complementos', sport: 'golf-es' },
        { key: 'electronica-007-es', label: 'Electrónica', sport: 'golf-es' },


        /** Kayak */

        { key: 'kayaks-es', label: 'Kayaks', sport: 'kayak-es' },
        { key: 'accesorios-008-es', label: 'Accesorios y equipamiento', sport: 'kayak-es' },
        { key: 'almacenamiento-y-transporte-008-es', label: 'Almacenamiento y Transporte', sport: 'kayak-es' },
        { key: 'neoprenos-008-es', label: 'Neoprenos', sport: 'kayak-es' },
        { key: 'electronica-008-es', label: 'Electrónica', sport: 'kayak-es' },


        /** Kitesurf **/

        { key: 'cometas-es', label: 'Cometas', sport: 'kitesurf-es' },
        { key: 'boards-es', label: 'Boards', sport: 'kitesurf-es' },
        { key: 'equipamiento-009-es', label: 'Equipamiento', sport: 'kitesurf-es' },
        { key: 'accesorios-009-es', label: 'Accesorios', sport: 'kitesurf-es' },
        { key: 'neoprenos-009-es', label: 'Neoprenos', sport: 'kitesurf-es' },
        { key: 'electronica-009-es', label: 'Electronica', sport: 'kitesurf-es' },


        /** Deportes raqueta **/

        { key: 'palas-raquetas-es', label: 'Palas y Raquetas', sport: 'deportes-raqueta-es' },
        { key: 'entrenamiento-010-es', label: 'Entrenamiento', sport: 'deportes-raqueta-es' },
        { key: 'accesorios-010-es', label: 'Accesorios', sport: 'deportes-raqueta-es' },
        { key: 'calzado-010-es', label: 'Calzado', sport: 'deportes-raqueta-es' },
        { key: 'ropa-010-es', label: 'Ropa', sport: 'deportes-raqueta-es' },
        { key: 'electronica-010-es', label: 'Electrónica', sport: 'deportes-raqueta-es' },


        /** Paddle surf **/

        { key: 'tablas-011-es', label: 'Tablas', sport: 'paddle-surf-es' },
        { key: 'accesorios-011-es', label: 'Accesorios y equipamiento ', sport: 'paddle-surf-es' },
        { key: 'almacenamiento-y-transporte-011-es', label: 'Almacenamiento y transporte', sport: 'paddle-surf-es' },
        { key: 'neoprenos-011-es', label: 'Neoprenos', sport: 'paddle-surf-es' },
        { key: 'electronica-011-es', label: 'Electrónica', sport: 'paddle-surf-es' },


        /** Running **/

        { key: 'calzado-012-es', label: 'Calzado', sport: 'running-es' },
        { key: 'ropa-012-es', label: 'Ropa', sport: 'running-es' },
        { key: 'equipamiento-012-es', label: 'Equipamiento', sport: 'running-es' },
        { key: 'electronica-012-es', label: 'Electrónica', sport: 'running-es' },


        /** Ski **/

        { key: 'esquis-es', label: 'Esquis', sport: 'ski-es' },
        { key: 'botas-013-es', label: 'Botas', sport: 'ski-es' },
        { key: 'ropa-013-es', label: 'Ropa', sport: 'ski-es' },
        { key: 'accesorios-013-es', label: 'Accesorios', sport: 'ski-es' },
        { key: 'protecciones-013-es', label: 'Protecciones', sport: 'ski-es' },
        { key: 'electronica-013-es', label: 'Electrónica', sport: 'ski-es' },


        /** Snowboard **/

        { key: 'tablas-de-snowboard-es', label: 'Tablas de Snowboard', sport: 'snowboard-es' },
        { key: 'botas-014-es', label: 'Botas', sport: 'snowboard-es' },
        { key: 'ropa-014-es', label: 'Ropa', sport: 'snowboard-es' },
        { key: 'accesorios-014-es', label: 'Accesorios', sport: 'snowboard-es' },
        { key: 'protecciones-014-es', label: 'Protecciones', sport: 'snowboard-es' },
        { key: 'electronica-014-es', label: 'Electrónica', sport: 'snowboard-es' },


        /** Surf **/

        { key: 'tablas-de-surf-es', label: 'Tablas de surf', sport: 'surf-es' },
        { key: 'neoprenos-015-es', label: 'Neoprenos', sport: 'surf-es' },
        { key: 'accesorios-015-es', label: 'Accesorios', sport: 'surf-es' },
        { key: 'electronica-015-es', label: 'Electrónica', sport: 'surf-es' },


        /** Windsurf **/

        { key: 'tablas-016-es', label: 'Tablas', sport: 'windsurf-es' },
        { key: 'velas-016-es', label: 'Velas', sport: 'windsurf-es' },
        { key: 'equipamiento-016-es', label: 'Equipamiento', sport: 'windsurf-es' },
        { key: 'neoprenos-016-es', label: 'Neoprenos', sport: 'windsurf-es' },
        { key: 'accesorios-016-es', label: 'Accesorios', sport: 'windsurf-es' },
        { key: 'electronica-016-es', label: 'Electrónica', sport: 'windsurf-es' },


        /** Tech **/

        { key: 'tech-017-es', label: 'Tech', sport: 'tech-es' },


        /** Otros deportes **/

        { key: 'alpinismo-018-es', label: 'Alpinismo', sport: 'other-sports-es' },
        { key: 'atletismo-018-es', label: 'Atletismo', sport: 'other-sports-es' },
        { key: 'baloncesto-018-es', label: 'Baloncesto', sport: 'other-sports-es' },
        { key: 'balonmano-018-es', label: 'Balonmano', sport: 'other-sports-es' },
        { key: 'beisbol-018-es', label: 'Beisbol', sport: 'other-sports-es' },
        { key: 'bodyboard-018-es', label: 'Bodyboard', sport: 'other-sports-es' },
        { key: 'calistenia-018-es', label: 'Calistenia', sport: 'other-sports-es' },
        { key: 'camping-018-es', label: 'Camping', sport: 'other-sports-es' },
        { key: 'danza-018-es', label: 'Danza', sport: 'other-sports-es' },
        { key: 'dardos-018-es', label: 'Dardos', sport: 'other-sports-es' },
        { key: 'equitacion-018-es', label: 'Equitación', sport: 'other-sports-es' },
        { key: 'esgrima-018-es', label: 'Esgrima', sport: 'other-sports-es' },
        { key: 'futbol-018-es', label: 'Fútbol', sport: 'other-sports-es' },
        { key: 'futbol-americano-018-es', label: 'Fútbol Americano', sport: 'other-sports-es' },
        { key: 'futbol-sala-018-es', label: 'Fútbol Sala', sport: 'other-sports-es' },
        { key: 'gimnasia-deportiva-018-es', label: 'Gimnasia Deportiva', sport: 'other-sports-es' },
        { key: 'hockey-hierba-018-es', label: 'Hockey hierba', sport: 'other-sports-es' },
        { key: 'hockey-hielo-018-es', label: 'Hockey hielo', sport: 'other-sports-es' },
        { key: 'natacion-018-es', label: 'Natación', sport: 'other-sports-es' },
        { key: 'patinaje-018-es', label: 'Patinaje', sport: 'other-sports-es' },
        { key: 'pilates-018-es', label: 'Pilates', sport: 'other-sports-es' },
        { key: 'rugby-018-es', label: 'Rugby', sport: 'other-sports-es' },
        { key: 'skate-y-longboard-018-es', label: 'Skate y longboard', sport: 'other-sports-es' },
        { key: 'voleibol-018-es', label: 'Voleibol', sport: 'other-sports-es' },
        { key: 'voley-playa-018-es', label: 'Voley playa', sport: 'other-sports-es' },
        { key: 'wakeboard-y-esqui-acuatico-018-es', label: 'Wakeboard y esquí acuatico', sport: 'other-sports-es' },
        { key: 'waterpolo-018-es', label: 'Waterpolo', sport: 'other-sports-es' },
        { key: 'yoga-018-es', label: 'Yoga', sport: 'other-sports-es' },
        { key: 'otros-018-es', label: 'Otros', sport: 'other-sports-es' },
      ],
    },
  },
  {
    id: 'subcategory',
    label: 'Subcategoría',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_subcategory'],
    config: {
      options: [

        /** Ciclismo **/

        { key: 'carretera', label: 'Carretera', sport: 'bicicletas-es' },
        { key: 'gravel', label: 'Gravel', sport: 'bicicletas-es' },
        { key: 'urbana', label: 'Urbana', sport: 'bicicletas-es' },
        { key: 'mtb-mountainbike', label: 'MTB / Mountainbike', sport: 'bicicletas-es' },
        { key: 'enduro', label: 'Enduro', sport: 'bicicletas-es' },
        { key: 'descenso', label: 'Descenso', sport: 'bicicletas-es' },
        { key: 'bmx', label: 'BMX', sport: 'bicicletas-es' },
        { key: 'electrica', label: 'Eléctrica', sport: 'bicicletas-es' },
        { key: 'estatica', label: 'Estática', sport: 'bicicletas-es' },
        { key: 'rodillos', label: 'Rodillos', sport: 'bicicletas-es' },
        { key: 'otros', label: 'Otros', sport: 'bicicletas-es' },

        { key: 'mantenimiento-herramientas-lubricantes-limpieza-bomba-bottellas-de-agua', label: 'Mantenimiento: herramientas, lubricantes, limpieza, bomba, botellas de agua', sport: 'componentes-es' },
        { key: 'amortiguacion-horquilla', label: 'Amortiguación y Horquilla', sport: 'componentes-es' },
        { key: 'grupos-manivelas-cadenas-bielas-transmisores', label: 'Grupos, Manivelas, cadenas, bielas, transmisiones', sport: 'componentes-es' },
        { key: 'pedales-calas', label: 'Pedales y calas', sport: 'componentes-es' },
        { key: 'rupturas-almohadillas-rotores', label: 'Rupturas, almohadillas y rotores', sport: 'componentes-es' },
        { key: 'guardabarros', label: 'Guardabarros', sport: 'componentes-es' },
        { key: 'manillar-frenos-manetas-punos-cintas', label: 'Manillar, Frenos, Manetas, Punos, Cintas', sport: 'componentes-es' },
        { key: 'sillin-tijas', label: 'Sillin y Tijas', sport: 'componentes-es' },
        { key: 'otros', label: 'Otros', sport: 'componentes-es' },

        { key: 'carretera-aero', label: 'Carretera y aero', sport: 'cuadros-es' },
        { key: 'mtb', label: 'MTB', sport: 'cuadros-es' },
        { key: 'otros', label: 'Otros', sport: 'cuadros-es' },

        { key: 'ruedas', label: 'Ruedas', sport: 'ruedas-es' },
        { key: 'camaras', label: 'Cámaras', sport: 'ruedas-es' },
        { key: 'cubiertas', label: 'Cubiertas', sport: 'ruedas-es' },
        { key: 'ejes-cierres', label: 'Ejes y cierres', sport: 'ruedas-es' },
        { key: 'bujes', label: 'Bujes', sport: 'ruedas-es' },
        { key: 'llantas', label: 'Llantas', sport: 'ruedas-es' },
        { key: 'otros', label: 'Otros', sport: 'ruedas-es' },

        { key: 'cascos', label: 'Cascos', sport: 'protecciones-001-es' },
        { key: 'protecciones-corporales', label: 'Protecciones corporales', sport: 'protecciones-001-es' },
        { key: 'gafas', label: 'Gafas', sport: 'protecciones-001-es' },
        { key: 'mascaras', label: 'Máscaras', sport: 'protecciones-001-es' },
        { key: 'reflectantes', label: 'Reflectantes', sport: 'protecciones-001-es' },
        { key: 'calzado', label: 'Calzado', sport: 'protecciones-001-es' },
        { key: 'otros', label: 'Otros', sport: 'protecciones-001-es' },

        { key: 'camisetas-chalecos-chaquetas-maillots', label: 'Camisetas, Chalecos, chaquetas, Maillots', sport: 'ropa-001-es' },
        { key: 'pantalones-culotes', label: 'Pantalones, Culotes', sport: 'ropa-001-es' },
        { key: 'triathlon', label: 'Triathlon', sport: 'ropa-001-es' },
        { key: 'calcetines', label: 'Calcetines', sport: 'ropa-001-es' },
        { key: 'guantes', label: 'Guantes', sport: 'ropa-001-es' },
        { key: 'gorras-gorros', label: 'Gorras y gorros', sport: 'ropa-001-es' },
        { key: 'calzado', label: 'Calzado', sport: 'ropa-001-es' },
        { key: 'otros', label: 'Otros', sport: 'ropa-001-es' },

        { key: 'alfojas', label: 'Alforjas', sport: 'bolsas-fundas-es' },
        { key: 'mochila', label: 'Mochila', sport: 'bolsas-fundas-es' },
        { key: 'bolsa-para-bicicleta', label: 'Bolsa para bicicleta', sport: 'bolsas-fundas-es' },
        { key: 'bolsa-de-viaje', label: 'Bolsa de viaje', sport: 'bolsas-fundas-es' },
        { key: 'cesta', label: 'Cesta', sport: 'bolsas-fundas-es' },
        { key: 'bolsa-de-herramientas', label: 'Bolsa de herramientas', sport: 'bolsas-fundas-es' },
        { key: 'portaequipaje', label: 'Portaequipajes', sport: 'bolsas-fundas-es' },
        { key: 'otros', label: 'Otros', sport: 'bolsas-fundas-es' },

        { key: 'auriculares', label: 'Auriculares', sport: 'electronica-001-es' },
        { key: 'camaras-de-accion', label: 'Camaras de accion', sport: 'electronica-001-es' },
        { key: 'cargadores-y-baterias', label: 'Cargadores y baterias', sport: 'electronica-001-es' },
        { key: 'relojes-pulseras-sensores', label: 'Relojes, Pulseras y Sensores', sport: 'electronica-001-es' },
        { key: 'ciclocumpatodores', label: 'Ciclocumpatodores', sport: 'electronica-001-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'electronica-001-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-001-es' },

        { key: 'soporte-de-bicicletas', label: 'Soporte de bicicletas', sport: 'transporte-es' },
        { key: 'barras-techo', label: 'Barras techo', sport: 'transporte-es' },
        { key: 'candados', label: 'Candados', sport: 'transporte-es' },
        { key: 'cofres-bacas', label: 'Cofres y Bacas', sport: 'transporte-es' },
        { key: 'fundas-de-bicicleta', label: 'Fundas de Bicicleta', sport: 'transporte-es' },
        { key: 'portabicicletas', label: 'Portabicicletas', sport: 'transporte-es' },
        { key: 'otros', label: 'Otros', sport: 'transporte-es' },


        /** Boxeo & Kickboxing **/

        { key: 'guantes', label: 'Guantes', sport: 'equipamiento-002-es' },
        { key: 'vendas', label: 'Vendas', sport: 'equipamiento-002-es' },
        { key: 'mitones', label: 'Mitones', sport: 'equipamiento-002-es' },
        { key: 'manoplas', label: 'Manoplas', sport: 'equipamiento-002-es' },
        { key: 'saco-de-pegada', label: 'Saco de pegada', sport: 'equipamiento-002-es' },
        { key: 'punching-ball', label: 'Punching ball', sport: 'equipamiento-002-es' },
        { key: 'paos', label: 'Paos', sport: 'equipamiento-002-es' },
        { key: 'otros', label: 'Otros', sport: 'equipamiento-002-es' },

        { key: 'kimonos-karateguis-judoguis-cinturones', label: 'Kimonos, Karateguis, Judoguis & Cinturones', sport: 'ropa-calzado-es' },
        { key: 'camisetas', label: 'Camisetas', sport: 'ropa-calzado-es' },
        { key: 'pantalones', label: 'Pantalones', sport: 'ropa-calzado-es' },
        { key: 'botas', label: 'Botas', sport: 'ropa-calzado-es' },
        { key: 'otros', label: 'Otros', sport: 'ropa-calzado-es' },

        { key: 'cascos', label: 'Cascos', sport: 'protecciones-002-es' },
        { key: 'protectores-de-pecho', label: 'Protectores de pecho', sport: 'protecciones-002-es' },
        { key: 'coquillas', label: 'Coquillas', sport: 'protecciones-002-es' },
        { key: 'espinilleras', label: 'Espinilleras', sport: 'protecciones-002-es' },
        { key: 'coderas', label: 'Coderas', sport: 'protecciones-002-es' },
        { key: 'tobilleras', label: 'Tobilleras', sport: 'protecciones-002-es' },
        { key: 'otros', label: 'Otros', sport: 'protecciones-002-es' },

        { key: 'auriculares', label: 'Auriculares', sport: 'electronica-002-es' },
        { key: 'camaras-de-accion', label: 'Camaras de accion', sport: 'electronica-002-es' },
        { key: 'cargadores-baterias', label: 'Cargadores y baterias', sport: 'electronica-002-es' },
        { key: 'relojes-pulseras-sensores', label: 'Relojes, Pulseras y sensores', sport: 'electronica-002-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'electronica-002-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-002-es' },

        /** Fitness **/

        { key: 'cintas-de-correr', label: 'Cintas de Correr', sport: 'maquinas-gimnasio-es' },
        { key: 'bicicletas-elipticas', label: 'Bicicletas Elipticas', sport: 'maquinas-gimnasio-es' },
        { key: 'remos', label: 'Remos', sport: 'maquinas-gimnasio-es' },
        { key: 'bicleta-indoor', label: 'Bicleta indoor', sport: 'maquinas-gimnasio-es' },
        { key: 'bicleta-estatica', label: 'Bicleta estatica', sport: 'maquinas-gimnasio-es' },
        { key: 'air-bike', label: 'Air bike', sport: 'maquinas-gimnasio-es' },
        { key: 'skii', label: 'Skii', sport: 'maquinas-gimnasio-es' },
        { key: 'otros', label: 'Otros', sport: 'maquinas-gimnasio-es' },
        { key: 'maquinas-multiestación', label: 'Máquinas Multiestación', sport: 'maquinas-gimnasio-es' },
        { key: 'agarres-manerales', label: 'Agarres y Manerales', sport: 'maquinas-gimnasio-es' },
        { key: 'jaulas-racks', label: 'Jaulas y Racks', sport: 'maquinas-gimnasio-es' },
        { key: 'maquinas-musculacion-tren-superior', label: 'Máquinas Musculación Tren Superior', sport: 'maquinas-gimnasio-es' },
        { key: 'maquinas-ejercicios-abdominales', label: 'Maquinas Ejercicios Abdominales', sport: 'maquinas-gimnasio-es' },
        { key: 'maquinas-musculacion-tren-inferior', label: 'Máquinas Musculación Tren Inferior', sport: 'maquinas-gimnasio-es' },
        { key: 'bancos-de-musculacion', label: 'Bancos de Musculación', sport: 'maquinas-gimnasio-es' },
        { key: 'accesorios-para-maquinas-musculacion', label: 'Accesorios para Máquinas Musculación', sport: 'maquinas-gimnasio-es' },
        { key: 'accesorios-de-musculacion', label: 'Accesorios de Musculación', sport: 'maquinas-gimnasio-es' },
        { key: 'otras', label: 'Otras', sport: 'maquinas-gimnasio-es' },

        { key: 'mancuernas', label: 'Mancuernas', sport: 'peso-libre-es' },
        { key: 'barras-con-peso', label: 'Barras con Peso', sport: 'peso-libre-es' },
        { key: 'barras-olimpicas', label: 'Barras Olímpicas', sport: 'peso-libre-es' },
        { key: 'discos-olimpicos', label: 'Discos Olímpicos', sport: 'peso-libre-es' },
        { key: 'otros', label: 'Otros', sport: 'peso-libre-es' },

        { key: 'entrenamiento-en-suspension', label: 'Entrenamiento en suspension', sport: 'entrenamiento-funcional-es' },
        { key: 'ketellbells', label: 'Ketellbells', sport: 'entrenamiento-funcional-es' },
        { key: 'sacos-funcionales', label: 'Sacos funcionales', sport: 'entrenamiento-funcional-es' },
        { key: 'cajones', label: 'Cajones', sport: 'entrenamiento-funcional-es' },
        { key: 'balones-medicinales', label: 'Balones medicinales', sport: 'entrenamiento-funcional-es' },
        { key: 'wall-balls', label: 'Wall balls', sport: 'entrenamiento-funcional-es' },
        { key: 'slam-balls', label: 'Slam Balls', sport: 'entrenamiento-funcional-es' },
        { key: 'bandas-elasticas', label: 'Bandas elasticas', sport: 'entrenamiento-funcional-es' },
        { key: 'chalecos', label: 'Chalecos', sport: 'entrenamiento-funcional-es' },
        { key: 'comba', label: 'Comba', sport: 'entrenamiento-funcional-es' },
        { key: 'esterilla-colchoneta', label: 'Esterilla/ colchoneta', sport: 'entrenamiento-funcional-es' },
        { key: 'aros', label: 'Aros', sport: 'entrenamiento-funcional-es' },
        { key: 'zafus', label: 'Zafús', sport: 'entrenamiento-funcional-es' },
        { key: 'fitness-ball', label: 'Fitness ball', sport: 'entrenamiento-funcional-es' },
        { key: 'bossu', label: 'Bossu', sport: 'entrenamiento-funcional-es' },
        { key: 'barras-dominadas', label: 'Barras dominadas', sport: 'entrenamiento-funcional-es' },
        { key: 'cuerdas', label: 'Cuerdas', sport: 'entrenamiento-funcional-es' },

        { key: 'camisetas-tops', label: 'Camisetas, tops ', sport: 'ropa-003-es' },
        { key: 'mallas-shorts-pantalones', label: 'Mallas, Shorts y Pantalones', sport: 'ropa-003-es' },
        { key: 'chandal', label: 'Chandal', sport: 'ropa-003-es' },
        { key: 'otros', label: 'Otros', sport: 'ropa-003-es' },

        { key: 'zapatillas', label: 'Zapatillas', sport: 'calzado-003-es' },
        { key: 'zapatillas-ciclismo-indoor', label: 'Zapatillas ciclismo indoor', sport: 'calzado-003-es' },

        { key: 'cinturon-lumbar', label: 'Cinturon lumbar', sport: 'accesorios-003-es' },
        { key: 'guantes-y-munequeras', label: 'Guantes y muñequeras', sport: 'accesorios-003-es' },
        { key: 'bolsas-y-mochilas', label: 'Bolsas y mochilas', sport: 'accesorios-003-es' },
        { key: 'candado-taquilla', label: 'Candado taquilla', sport: 'accesorios-003-es' },
        { key: 'otros', label: 'Otros', sport: 'accesorios-003-es' },

        { key: 'auriculares', label: 'Auriculares', sport: 'electronica-003-es' },
        { key: 'camaras-de-accion', label: 'Camaras de accion', sport: 'electronica-003-es' },
        { key: 'cargadores-y-baterias', label: 'Cargadores y baterias', sport: 'electronica-003-es' },
        { key: 'relojes-pulseras-y-sensores', label: 'Relojes, Pulseras y sensores', sport: 'electronica-003-es' },
        { key: 'ciclocumpatodores', label: 'Ciclocumpatodores', sport: 'electronica-003-es' },
        { key: 'basculas', label: 'Basculas', sport: 'electronica-003-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'electronica-003-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-003-es' },

        { key: 'alimentacion', label: 'Alimentacion', sport: 'salud-nutricion-es' },
        { key: 'suplementacion', label: 'Suplementación', sport: 'salud-nutricion-es' },
        { key: 'otros', label: 'Otros', sport: 'salud-nutricion-es' },

        /** Buceo y Snorkel **/

        { key: 'mascaras-faciales-y-tubos', label: 'Mascaras faciales y tubos', sport: 'equipamiento-004-es' },
        { key: 'aletas-y-monoaletas', label: 'Aletas y monoaletas', sport: 'equipamiento-004-es' },
        { key: 'reguladores-y-octopus', label: 'Reguladores  y octopus', sport: 'equipamiento-004-es' },
        { key: 'kit-snorkel', label: 'Kit snorkel', sport: 'equipamiento-004-es' },
        { key: 'chalecos-pesos-y-latiguillos', label: 'Chalecos, pesos y latiguillos', sport: 'equipamiento-004-es' },
        { key: 'otros', label: 'Otros', sport: 'equipamiento-004-es' },

        { key: 'botellas', label: 'Botellas', sport: 'botellas-instrumentos-es' },
        { key: 'manometro', label: 'Manometro', sport: 'botellas-instrumentos-es' },
        { key: 'trasvasadores', label: 'Trasvasadores', sport: 'botellas-instrumentos-es' },
        { key: 'brujulas', label: 'Brujulas', sport: 'botellas-instrumentos-es' },
        { key: 'profundimetros', label: 'Profundimetros', sport: 'botellas-instrumentos-es' },
        { key: 'griferias', label: 'Griferias', sport: 'botellas-instrumentos-es' },
        { key: 'compresores', label: 'Compresores', sport: 'botellas-instrumentos-es' },
        { key: 'analizadores', label: 'Analizadores', sport: 'botellas-instrumentos-es' },
        { key: 'cinchas-back-pack-y-aros', label: 'Cinchas, back pack y aros', sport: 'botellas-instrumentos-es' },
        { key: 'otros', label: 'Otros', sport: 'botellas-instrumentos-es' },

        { key: 'traje-semiseco', label: 'Traje semiseco', sport: 'neopreno-es' },
        { key: 'traje-seco', label: 'Traje seco', sport: 'neopreno-es' },
        { key: 'traje-humedo', label: 'Traje humedo', sport: 'neopreno-es' },
        { key: 'proteccion-termica', label: 'Proteccion termica', sport: 'neopreno-es' },
        { key: 'guantes', label: 'Guantes', sport: 'neopreno-es' },
        { key: 'capucha', label: 'Capucha', sport: 'neopreno-es' },
        { key: 'otros', label: 'Otros', sport: 'neopreno-es' },

        { key: 'bolsa-aletas', label: 'Bolsa aletas', sport: 'accesorios-004-es' },
        { key: 'bolsa-para-traje', label: 'Bolsa para traje', sport: 'accesorios-004-es' },
        { key: 'funda-mascara', label: 'Funda Mascara', sport: 'accesorios-004-es' },
        { key: 'clips-nariz-apnea', label: 'Clips nariz apnea', sport: 'accesorios-004-es' },
        { key: 'bolsa-regulador', label: 'Bolsa regulador', sport: 'accesorios-004-es' },
        { key: 'latiguillos', label: 'Latiguillos', sport: 'accesorios-004-es' },
        { key: 'recambios', label: 'Recambios', sport: 'accesorios-004-es' },
        { key: 'evillas-y-arneses', label: 'Evillas y arneses', sport: 'accesorios-004-es' },
        { key: 'otros', label: 'Otros', sport: 'accesorios-004-es' },

        { key: 'camara-de-accion', label: 'Camara de accion', sport: 'electronica-004-es' },
        { key: 'cargadores-y-baterias', label: 'Cargadores y baterias', sport: 'electronica-004-es' },
        { key: 'focos-y-linternas', label: 'Focos y linternas', sport: 'electronica-004-es' },
        { key: 'consolas', label: 'Consolas', sport: 'electronica-004-es' },
        { key: 'reguladores-y-octopus', label: 'Reguladores  y octopus', sport: 'electronica-004-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-004-es' },

        { key: 'boyas', label: 'Boyas', sport: 'seguridad-004-es' },
        { key: 'chalecos', label: 'Chalecos', sport: 'seguridad-004-es' },
        { key: 'marcadores-de-cabo', label: 'Marcadores de cabo', sport: 'seguridad-004-es' },
        { key: 'sistem', label: 'Sistem', sport: 'seguridad-004-es' },


        /** Deporte Adaptado **/

        { key: 'handbike-electrica', label: 'Handbike electrica', sport: 'handbike-bicicletas-adaptadas-es' },
        { key: 'handbike-carretera', label: 'Handbike carretera', sport: 'handbike-bicicletas-adaptadas-es' },
        { key: 'handbike-de-competicion', label: 'Handbike de competición', sport: 'handbike-bicicletas-adaptadas-es' },
        { key: 'triciclo-adaptado', label: 'Triciclo adaptado', sport: 'handbike-bicicletas-adaptadas-es' },
        { key: 'otros', label: 'Otros', sport: 'handbike-bicicletas-adaptadas-es' },

        { key: 'tenis-o-paddel', label: 'Tenis o Paddel', sport: 'silla-de-ruedas-es' },
        { key: 'baloncesto', label: 'Baloncesto', sport: 'silla-de-ruedas-es' },
        { key: 'carrera', label: 'Carrera', sport: 'silla-de-ruedas-es' },
        { key: 'esqui', label: 'Esqui', sport: 'silla-de-ruedas-es' },
        { key: 'golf', label: 'Golf', sport: 'silla-de-ruedas-es' },
        { key: 'joelettes', label: 'Joëlettes', sport: 'silla-de-ruedas-es' },
        { key: 'accesorios', label: 'Accesorios', sport: 'silla-de-ruedas-es' },
        { key: 'otros', label: 'Otros', sport: 'silla-de-ruedas-es' },

        { key: 'cinta-de-correr-adaptadas-y-antigravedad', label: 'Cinta de correr adaptadas y antigravedad', sport: 'fitness-005-es' },
        { key: 'cintas-cuerdas', label: 'Cintas & cuerdas', sport: 'fitness-005-es' },
        { key: 'flotadores-cinturones', label: 'Flotadores & Cinturones', sport: 'fitness-005-es' },
        { key: 'otros', label: 'Otros', sport: 'fitness-005-es' },

        { key: 'paddle', label: 'Paddle', sport: 'deportes-de-agua-es' },
        { key: 'kayak', label: 'Kayak', sport: 'deportes-de-agua-es' },
        { key: 'remo', label: 'Remo', sport: 'deportes-de-agua-es' },
        { key: 'vela', label: 'Vela', sport: 'deportes-de-agua-es' },
        { key: 'surf', label: 'Surf', sport: 'deportes-de-agua-es' },
        { key: 'esqui-acuatico', label: 'Esqui acuatico', sport: 'deportes-de-agua-es' },
        { key: 'wakeboarding', label: 'Wakeboarding', sport: 'deportes-de-agua-es' },
        { key: 'otros', label: 'Otros', sport: 'deportes-de-agua-es' },


        /** Escalada **/

        { key: 'pies-de-gato', label: 'Pies de Gato', sport: 'calzado-006-es' },
        { key: 'zapatillas-de-aproximacion', label: 'Zapatillas de aproximacion', sport: 'calzado-006-es' },
        { key: 'botas-de-montana', label: 'Botas de Montaña', sport: 'calzado-006-es' },
        { key: 'otros', label: 'Otros', sport: 'calzado-006-es' },

        { key: 'casco', label: 'Casco', sport: 'casco-arneses-es' },
        { key: 'arneses', label: 'Arneses', sport: 'casco-arneses-es' },
        { key: 'otros', label: 'Otros', sport: 'casco-arneses-es' },

        { key: 'dinamica', label: 'Dinamica', sport: 'cuerdas-cintas-es' },
        { key: 'estatica', label: 'Estatica', sport: 'cuerdas-cintas-es' },
        { key: 'cordines', label: 'Cordines', sport: 'cuerdas-cintas-es' },
        { key: 'cintas-y-anillas', label: 'Cintas y anillas', sport: 'cuerdas-cintas-es' },
        { key: 'daisy-chains-y-estribos', label: 'Daisy chains y estribos', sport: 'cuerdas-cintas-es' },
        { key: 'otros', label: 'Otros', sport: 'cuerdas-cintas-es' },

        { key: 'mosquetones', label: 'Mosquetones', sport: 'ferreteria-es' },
        { key: 'cintas-express', label: 'Cintas Express', sport: 'ferreteria-es' },
        { key: 'aseguradores-y-descendores', label: 'Aseguradores y descendores', sport: 'ferreteria-es' },
        { key: 'ascendores-y-bloqueadores', label: 'Ascendores y bloqueadores', sport: 'ferreteria-es' },
        { key: 'poleas', label: 'Poleas', sport: 'ferreteria-es' },
        { key: 'empotradores', label: 'Empotradores', sport: 'ferreteria-es' },
        { key: 'clavos-y-hooks', label: 'Clavos y Hooks', sport: 'ferreteria-es' },
        { key: 'anclajes', label: 'Anclajes', sport: 'ferreteria-es' },
        { key: 'otros', label: 'Otros', sport: 'ferreteria-es' },

        { key: 'piolets-de-marcha', label: 'Piolets de marcha', sport: 'nieve-hielo-es' },
        { key: 'piolets-tecnicos', label: 'Piolets tecnicos', sport: 'nieve-hielo-es' },
        { key: 'crampones', label: 'Crampones', sport: 'nieve-hielo-es' },
        { key: 'tornillos-y-estacas', label: 'Tornillos y estacas', sport: 'nieve-hielo-es' },
        { key: 'raquetas', label: 'Raquetas', sport: 'nieve-hielo-es' },
        { key: 'palas-sondas-arva', label: 'Palas, Sondas, Arva', sport: 'nieve-hielo-es' },
        { key: 'otros', label: 'Otros', sport: 'nieve-hielo-es' },

        { key: 'lentes-y-antiparras', label: 'Lentes y antiparras', sport: 'esenciales-es' },
        { key: 'polainas', label: 'Polainas', sport: 'esenciales-es' },
        { key: 'presas-y-tablas', label: 'Presas y tablas', sport: 'esenciales-es' },
        { key: 'magnesio-y-magnesieros', label: 'Magnesio y magnesieros', sport: 'esenciales-es' },
        { key: 'crash-pads', label: 'Crash Pads', sport: 'esenciales-es' },
        { key: 'petates', label: 'Petates', sport: 'esenciales-es' },
        { key: 'guantes-de-proteccion', label: 'Guantes de proteccion', sport: 'esenciales-es' },
        { key: 'otros', label: 'Otros', sport: 'esenciales-es' },

        { key: 'camaras-de-accion', label: 'Camaras de accion', sport: 'electronica-006-es' },
        { key: 'relojes-y-gps', label: 'Relojes y GPS', sport: 'electronica-006-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'electronica-006-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-006-es' },

        /** Golf **/

        { key: 'drivers', label: 'Drivers', sport: 'palos-es' },
        { key: 'maderas', label: 'Maderas', sport: 'palos-es' },
        { key: 'hierros', label: 'Hierros', sport: 'palos-es' },
        { key: 'putter', label: 'Putter', sport: 'palos-es' },
        { key: 'hibridos', label: 'Híbridos', sport: 'palos-es' },
        { key: 'wedges', label: 'Wedges', sport: 'palos-es' },

        { key: 'bolas-recoge-bolas-tees-recogebolas-y-arreglapiques', label: 'Bolas, Recoge Bolas, Tees recogebolas y arreglapiques', sport: 'accesorios-007-es' },
        { key: 'carros', label: 'Carros', sport: 'accesorios-007-es' },
        { key: 'fundas', label: 'Fundas', sport: 'accesorios-007-es' },
        { key: 'redes-y-alfombras', label: 'Redes y alfombras', sport: 'accesorios-007-es' },
        { key: 'paraguas-y-portaparaguas', label: 'Paraguas y portaparaguas', sport: 'accesorios-007-es' },
        { key: 'otros', label: 'Otros', sport: 'accesorios-007-es' },

        { key: 'zapatos-tacos', label: 'Zapatos & tacos', sport: 'calzado-007-es' },

        { key: 'camisetas-sudaderas-chaquetas', label: 'Camisetas, sudaderas, chaquetas', sport: 'ropa-007-es' },
        { key: 'pantalones-shorts-mallas', label: 'Pantalones, shorts, mallas', sport: 'ropa-007-es' },
        { key: 'faldas-vestidos', label: 'Faldas, vestidos', sport: 'ropa-007-es' },
        { key: 'tops-y-sujetadores-deportivos', label: 'Tops y sujetadores deportivos', sport: 'ropa-007-es' },
        { key: 'gorras', label: 'Gorras', sport: 'ropa-007-es' },
        { key: 'guantes', label: 'Guantes', sport: 'ropa-007-es' },
        { key: 'otros', label: 'Otros', sport: 'ropa-007-es' },

        { key: 'camaras-de-accion', label: 'Camaras de accion', sport: 'electronica-007-es' },
        { key: 'auriculares', label: 'Auriculares', sport: 'electronica-007-es' },
        { key: 'cargadores-y-baterias', label: 'Cargadores y baterias', sport: 'electronica-007-es' },
        { key: 'linternas', label: 'Linternas', sport: 'electronica-007-es' },
        { key: 'relojes-y-sensores', label: 'Relojes y sensores', sport: 'electronica-007-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'electronica-007-es' },
        { key: 'medidor-laser', label: 'Medidor laser', sport: 'electronica-007-es' },
        { key: 'gps', label: 'GPS', sport: 'electronica-007-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-007-es' },


        /** Kayak **/

        { key: 'rigido', label: 'Rigido', sport: 'kayaks-es' },
        { key: 'hinchable', label: 'Hinchable', sport: 'kayaks-es' },

        { key: 'palas-y-remos', label: 'Palas y Remos', sport: 'accesorios-008-es' },
        { key: 'bolsas-y-mochilas-impermeables', label: 'Bolsas y mochilas impermeables', sport: 'accesorios-008-es' },
        { key: 'fundas', label: 'Fundas', sport: 'accesorios-008-es' },
        { key: 'chalecos-flotadores-y-estabilizadores', label: 'Chalecos, Flotadores y estabilizadores', sport: 'accesorios-008-es' },
        { key: 'asientos-y-cojines', label: 'Asientos y cojines', sport: 'accesorios-008-es' },
        { key: 'infladores', label: 'Infladores', sport: 'accesorios-008-es' },
        { key: 'otros', label: 'Otros', sport: 'accesorios-008-es' },

        { key: 'carros-transporte', label: 'Carros transporte', sport: 'almacenamiento-y-transporte-008-es' },
        { key: 'raks-y-soportes', label: 'Raks y soportes', sport: 'almacenamiento-y-transporte-008-es' },
        { key: 'otros', label: 'Otros', sport: 'almacenamiento-y-transporte-008-es' },

        { key: 'neoprenos', label: 'Neoprenos', sport: 'neoprenos-008-es' },
        { key: 'tops', label: 'Tops', sport: 'neoprenos-008-es' },
        { key: 'capuchas-escarpines-y-manoplas', label: 'Capuchas, escarpines y manoplas', sport: 'neoprenos-008-es' },
        { key: 'otros', label: 'Otros', sport: 'neoprenos-008-es' },

        { key: 'camara-de-accion', label: 'Camara de accion', sport: 'electronica-008-es' },
        { key: 'cargadores-y-baterias', label: 'Cargadores y baterias', sport: 'electronica-008-es' },
        { key: 'focos-y-linternas', label: 'Focos y linternas', sport: 'electronica-008-es' },
        { key: 'relojes-pulseras-y-sensores', label: 'Relojes, Pulseras y sensores', sport: 'electronica-008-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'electronica-008-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-008-es' },


        /** Kitesurf **/

        { key: 'freestyle', label: 'Freestyle', sport: 'cometas-es' },
        { key: 'freeride', label: 'Freeride', sport: 'cometas-es' },
        { key: 'ola', label: 'Ola', sport: 'cometas-es' },
        { key: 'foil', label: 'Foil', sport: 'cometas-es' },
        { key: 'light-wind', label: 'Light wind', sport: 'cometas-es' },
        { key: 'big-air', label: 'Big Air', sport: 'cometas-es' },
        { key: 'otros', label: 'Otros', sport: 'cometas-es' },

        { key: 'freestyle', label: 'Freestyle', sport: 'boards-es' },
        { key: 'freeride', label: 'Freeride', sport: 'boards-es' },
        { key: 'new-school', label: 'New school', sport: 'boards-es' },
        { key: 'olas', label: 'Olas', sport: 'boards-es' },
        { key: 'hydrofoil', label: 'Hydrofoil', sport: 'boards-es' },
        { key: 'wakeboard', label: 'Wakeboard', sport: 'boards-es' },
        { key: 'wing-surf', label: 'Wing surf', sport: 'boards-es' },
        { key: 'otros', label: 'Otros', sport: 'boards-es' },

        { key: 'arneses', label: 'Arneses', sport: 'equipamiento-009-es' },
        { key: 'barras', label: 'Barras', sport: 'equipamiento-009-es' },
        { key: 'pads-footsraps', label: 'PADS & FOOTSRAPS', sport: 'equipamiento-009-es' },
        { key: 'bindings', label: 'BINDINGS', sport: 'equipamiento-009-es' },
        { key: 'leash', label: 'Leash', sport: 'equipamiento-009-es' },
        { key: 'otros', label: 'Otros', sport: 'equipamiento-009-es' },

        { key: 'cascos', label: 'Cascos', sport: 'accesorios-009-es' },
        { key: 'chalecos', label: 'Chalecos', sport: 'accesorios-009-es' },
        { key: 'fundas', label: 'Fundas', sport: 'accesorios-009-es' },
        { key: 'hinchadores', label: 'Hinchadores', sport: 'accesorios-009-es' },
        { key: 'otros', label: 'Otros', sport: 'accesorios-009-es' },

        { key: 'invierno-43-54', label: 'Invierno (4/3, 5/4)', sport: 'neoprenos-009-es' },
        { key: 'verano-32-22-shorty', label: 'Verano (3/2, 2/2, shorty)', sport: 'neoprenos-009-es' },
        { key: 'lycra', label: 'Lycra', sport: 'neoprenos-009-es' },
        { key: 'capuchas-escarpines-y-guantes', label: 'Capuchas, Escarpines y guantes', sport: 'neoprenos-009-es' },
        { key: 'otros', label: 'Otros', sport: 'neoprenos-009-es' },

        { key: 'camaras-de-accion', label: 'Camaras de Accion', sport: 'electronica-009-es' },
        { key: 'relojes', label: 'Relojes', sport: 'electronica-009-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-009-es' },


        /** Deportes Raqueta **/

        { key: 'palas-padel', label: 'Palas Padel', sport: 'palas-raquetas-es' },
        { key: 'palas-ping-pong', label: 'Palas Ping Pong', sport: 'palas-raquetas-es' },
        { key: 'raquetas-tenis', label: 'Raquetas tenis', sport: 'palas-raquetas-es' },
        { key: 'raquetas-badminton', label: 'Raquetas badminton', sport: 'palas-raquetas-es' },
        { key: 'raquetas-squash', label: 'Raquetas squash', sport: 'palas-raquetas-es' },
        { key: 'raquetas-frontenis', label: 'Raquetas frontenis', sport: 'palas-raquetas-es' },
        { key: 'otros', label: 'Otros', sport: 'palas-raquetas-es' },

        { key: 'pelotas-y-volantes', label: 'Pelotas y volantes', sport: 'entrenamiento-010-es' },
        { key: 'botellas', label: 'Botellas', sport: 'entrenamiento-010-es' },
        { key: 'cestas-conos-y-escaleras-de-agilidad', label: 'Cestas, conos y escaleras de agilidad', sport: 'entrenamiento-010-es' },
        { key: 'mesa-ping-pong', label: 'Mesa Ping Pong', sport: 'entrenamiento-010-es' },
        { key: 'redes', label: 'Redes', sport: 'entrenamiento-010-es' },
        { key: 'otros', label: 'Otros', sport: 'entrenamiento-010-es' },

        { key: 'grips-overgrips', label: 'Grips , overgrips', sport: 'accesorios-010-es' },
        { key: 'cordaje', label: 'Cordaje', sport: 'accesorios-010-es' },
        { key: 'adaptadores', label: 'Adaptadores', sport: 'accesorios-010-es' },
        { key: 'otros', label: 'Otros', sport: 'accesorios-010-es' },

        { key: 'indoor', label: 'Indoor', sport: 'calzado-010-es' },
        { key: 'zapatillas-padel', label: 'Zapatillas padel', sport: 'calzado-010-es' },
        { key: 'zapatillas-tenis', label: 'Zapatillas tenis', sport: 'calzado-010-es' },
        { key: 'otros', label: 'Otros', sport: 'calzado-010-es' },

        { key: 'camisetas-sudaderas', label: 'Camisetas, sudaderas', sport: 'ropa-010-es' },
        { key: 'pantalones-shorts-mallas', label: 'Pantalones, shorts, mallas', sport: 'ropa-010-es' },
        { key: 'faldas-vestidos', label: 'Faldas, vestidos', sport: 'ropa-010-es' },
        { key: 'tops-y-sujetadores-deportivos', label: 'Tops y sujetadores deportivos', sport: 'ropa-010-es' },
        { key: 'chandal', label: 'Chandal', sport: 'ropa-010-es' },
        { key: 'otros', label: 'Otros', sport: 'ropa-010-es' },

        { key: 'auriculares', label: 'Auriculares', sport: 'electronica-010-es' },
        { key: 'camaras-de-accion', label: 'Camaras de accion', sport: 'electronica-010-es' },
        { key: 'cargadores-y-baterias', label: 'Cargadores y baterias', sport: 'electronica-010-es' },
        { key: 'relojes-pulseras-y-sensores', label: 'Relojes, Pulseras y sensores', sport: 'electronica-010-es' },
        { key: 'ciclocumpatodores', label: 'Ciclocumpatodores', sport: 'electronica-010-es' },
        { key: 'basculas', label: 'Basculas', sport: 'electronica-010-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'electronica-010-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-010-es' },


        /** Paddle Surf **/

        { key: 'hinchable', label: 'Hinchable', sport: 'tablas-011-es' },
        { key: 'rigida', label: 'Rigida', sport: 'tablas-011-es' },


        { key: 'remos', label: 'Remos', sport: 'accesorios-011-es' },
        { key: 'fundas', label: 'Fundas', sport: 'accesorios-011-es' },
        { key: 'inventos', label: 'Inventos', sport: 'accesorios-011-es' },
        { key: 'chalecos', label: 'Chalecos', sport: 'accesorios-011-es' },
        { key: 'quillas', label: 'Quillas', sport: 'accesorios-011-es' },
        { key: 'infladores', label: 'Infladores', sport: 'accesorios-011-es' },

        { key: 'carros-transporte', label: 'Carros transporte', sport: 'almacenamiento-y-transporte-011-es' },
        { key: 'raks-y-soportes', label: 'Raks y soportes', sport: 'almacenamiento-y-transporte-011-es' },
        { key: 'otros', label: 'Otros', sport: 'almacenamiento-y-transporte-011-es' },

        { key: 'neoprenos', label: 'Neoprenos', sport: 'neoprenos-011-es' },
        { key: 'tops', label: 'Tops', sport: 'neoprenos-011-es' },
        { key: 'capuchas-escarpines-y-manoplas', label: 'Capuchas, escarpines y manoplas', sport: 'neoprenos-011-es' },
        { key: 'otros', label: 'Otros', sport: 'neoprenos-011-es' },

        { key: 'camara-de-accion', label: 'Camara de accion', sport: 'electronica-011-es' },
        { key: 'cargadores-y-baterias', label: 'Cargadores y baterias', sport: 'electronica-011-es' },
        { key: 'focos-y-linternas', label: 'Focos y linternas', sport: 'electronica-011-es' },
        { key: 'relojes-pulseras-y-sensores', label: 'Relojes, Pulseras y sensores', sport: 'electronica-011-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'electronica-011-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-011-es' },


        /** Running **/

        { key: 'trail', label: 'Trail', sport: 'calzado-012-es' },
        { key: 'running', label: 'Running', sport: 'calzado-012-es' },
        { key: 'casual', label: 'Casual', sport: 'calzado-012-es' },
        { key: 'atletismo-clavos', label: 'Atletismo / Clavos', sport: 'calzado-012-es' },
        { key: 'otros', label: 'Otros', sport: 'calzado-012-es' },

        { key: 'camisetas-chalecos-chaquetas-cortavientos-sudaderas', label: 'Camisetas, Chalecos, Chaquetas, Cortavientos, Sudaderas', sport: 'ropa-012-es' },
        { key: 'mallas-pantalones-shorts', label: 'Mallas, pantalones, shorts', sport: 'ropa-012-es' },
        { key: 'guantes-manguitos', label: 'Guantes, manguitos', sport: 'ropa-012-es' },
        { key: 'calcetines-perneras', label: 'Calcetines, perneras', sport: 'ropa-012-es' },
        { key: 'gorrasbandas-tubulares', label: 'Gorras,Bandas, Tubulares', sport: 'ropa-012-es' },
        { key: 'chandals', label: 'Chandals', sport: 'ropa-012-es' },
        { key: 'triathlon', label: 'Triathlon', sport: 'ropa-012-es' },
        { key: 'otros', label: 'Otros', sport: 'ropa-012-es' },

        { key: 'gafas', label: 'Gafas', sport: 'equipamiento-012-es' },
        { key: 'bastones', label: 'Bastones', sport: 'equipamiento-012-es' },
        { key: 'brujulas', label: 'Brujulas', sport: 'equipamiento-012-es' },
        { key: 'crampones', label: 'Crampones', sport: 'equipamiento-012-es' },
        { key: 'polainas', label: 'Polainas', sport: 'equipamiento-012-es' },
        { key: 'otros', label: 'Otros', sport: 'equipamiento-012-es' },

        { key: 'camaras-de-accion', label: 'Camaras de accion', sport: 'electronica-012-es' },
        { key: 'auriculares', label: 'Auriculares', sport: 'electronica-012-es' },
        { key: 'cargadores-y-baterias', label: 'Cargadores y baterias', sport: 'electronica-012-es' },
        { key: 'linternas', label: 'Linternas', sport: 'electronica-012-es' },
        { key: 'relojes-y-sensores', label: 'Relojes y sensores', sport: 'electronica-012-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'electronica-012-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-012-es' },


        /** Ski **/

        { key: 'alpino-pista', label: 'Alpino / Pista', sport: 'esquis-es' },
        { key: 'all-mountain-polivalente', label: 'All mountain / Polivalente', sport: 'esquis-es' },
        { key: 'freeride', label: 'Freeride', sport: 'esquis-es' },
        { key: 'freestyle', label: 'Freestyle', sport: 'esquis-es' },
        { key: 'travesia-touring', label: 'Travesia (Touring)', sport: 'esquis-es' },
        { key: 'fondo-cross-country', label: 'Fondo (cross-country)', sport: 'esquis-es' },
        { key: 'telemark', label: 'Telemark', sport: 'esquis-es' },
        { key: 'fijaciones', label: 'Fijaciones', sport: 'esquis-es' },
        { key: 'otros', label: 'Otros', sport: 'esquis-es' },

        { key: 'alpino-pista', label: 'Alpino / Pista', sport: 'botas-013-es' },
        { key: 'all-mountain-polivalente', label: 'All mountain / Polivalente', sport: 'botas-013-es' },
        { key: 'freeride', label: 'Freeride', sport: 'botas-013-es' },
        { key: 'freestyle', label: 'Freestyle', sport: 'botas-013-es' },
        { key: 'travesia-touring', label: 'Travesia (Touring)', sport: 'botas-013-es' },
        { key: 'fondo-cross-country', label: 'Fondo (cross-country)', sport: 'botas-013-es' },
        { key: 'telemark', label: 'Telemark', sport: 'botas-013-es' },
        { key: 'otros', label: 'Otros', sport: 'botas-es' },

        { key: 'chaquetas-y-sudaderas', label: 'Chaquetas y Sudaderas', sport: 'ropa-013-es' },
        { key: 'pantalones', label: 'Pantalones', sport: 'ropa-013-es' },
        { key: 'gorros-bragas-y-guantes', label: 'Gorros, Bragas y Guantes', sport: 'ropa-013-es' },
        { key: 'ropa-termica-calcetines', label: 'Ropa Termica, calcetines', sport: 'ropa-013-es' },
        { key: 'otros', label: 'Otros', sport: 'ropa-013-es' },

        { key: 'bastones', label: 'Bastones', sport: 'accesorios-013-es' },
        { key: 'fundas-de-esqui-y-botas', label: 'Fundas de Esqui y Botas', sport: 'accesorios-013-es' },
        { key: 'fijaciones', label: 'Fijaciones', sport: 'accesorios-013-es' },
        { key: 'pieles-de-foca', label: 'Pieles de foca', sport: 'accesorios-013-es' },
        { key: 'mochilas', label: 'Mochilas', sport: 'accesorios-013-es' },
        { key: 'otros', label: 'Otros', sport: 'accesorios-013-es' },

        { key: 'casco', label: 'Casco', sport: 'protecciones-013-es' },
        { key: 'protecciones-corporales', label: 'Protecciones corporales', sport: 'protecciones-013-es' },
        { key: 'gafas-de-ventisca', label: 'Gafas de Ventisca', sport: 'protecciones-013-es' },
        { key: 'gafas-de-sol', label: 'Gafas de Sol', sport: 'protecciones-013-es' },
        { key: 'seguridad-de-avalanchas', label: 'Seguridad de avalanchas', sport: 'protecciones-013-es' },
        { key: 'otros', label: 'Otros', sport: 'protecciones-013-es' },

        { key: 'camaras', label: 'Camaras', sport: 'electronica-013-es' },
        { key: 'relojes', label: 'Relojes', sport: 'electronica-013-es' },
        { key: 'arvas-y-localizadores', label: 'Arvas y localizadores', sport: 'electronica-013-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'electronica-013-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-013-es' },


        /** Snowboard **/

        { key: 'alpino-pista', label: 'Alpino / Pista', sport: 'tablas-de-snowboard-es' },
        { key: 'all-mountain-polivalente', label: 'All mountain / Polivalente', sport: 'tablas-de-snowboard-es' },
        { key: 'freeride', label: 'Freeride', sport: 'tablas-de-snowboard-es' },
        { key: 'freestyle', label: 'Freestyle', sport: 'tablas-de-snowboard-es' },
        { key: 'splitboard', label: 'Splitboard', sport: 'tablas-de-snowboard-es' },
        { key: 'fijaciones', label: 'Fijaciones', sport: 'tablas-de-snowboard-es' },
        { key: 'otros', label: 'Otros', sport: 'tablas-de-snowboard-es' },

        { key: 'alpino-pista', label: 'Alpino / Pista', sport: 'botas-014-es' },
        { key: 'all-mountain-polivalente', label: 'All mountain / Polivalente', sport: 'botas-014-es' },
        { key: 'freeride', label: 'Freeride', sport: 'botas-014-es' },
        { key: 'freestyle', label: 'Freestyle', sport: 'botas-014-es' },
        { key: 'otros', label: 'Otros', sport: 'botas-014-es' },

        { key: 'chaquetas-y-sudaderas', label: 'Chaquetas y Sudaderas', sport: 'ropa-014-es' },
        { key: 'pantalones', label: 'Pantalones', sport: 'ropa-014-es' },
        { key: 'gorros-bragas-y-guantes', label: 'Gorros, Bragas y Guantes', sport: 'ropa-014-es' },
        { key: 'ropa-termica-calcetines', label: 'Ropa Termica, calcetines', sport: 'ropa-014-es' },
        { key: 'otros', label: 'Otros', sport: 'ropa-014-es' },

        { key: 'fundas-de-tabla-y-botas', label: 'Fundas de Tabla y Botas', sport: 'accesorios-014-es' },
        { key: 'fijaciones', label: 'Fijaciones', sport: 'accesorios-014-es' },
        { key: 'pieles-de-foca', label: 'Pieles de foca', sport: 'accesorios-014-es' },
        { key: 'mochilas', label: 'Mochilas', sport: 'accesorios-014-es' },
        { key: 'palas', label: 'Palas', sport: 'accesorios-014-es' },
        { key: 'otros', label: 'Otros', sport: 'accesorios-014-es' },

        { key: 'casco', label: 'Casco', sport: 'protecciones-014-es' },
        { key: 'protecciones-corporales', label: 'Protecciones corporales', sport: 'protecciones-014-es' },
        { key: 'gafas-de-ventisca', label: 'Gafas de Ventisca', sport: 'protecciones-014-es' },
        { key: 'gafas-de-sol', label: 'Gafas de Sol', sport: 'protecciones-014-es' },
        { key: 'seguridad-de-avalanchas', label: 'Seguridad de avalanchas', sport: 'protecciones-014-es' },
        { key: 'otros', label: 'Otros', sport: 'protecciones-014-es' },

        { key: 'camaras', label: 'Camaras', sport: 'electronica-014-es' },
        { key: 'relojes', label: 'Relojes', sport: 'electronica-014-es' },
        { key: 'arvas-y-localizadores', label: 'Arvas y localizadores', sport: 'electronica-014-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'electronica-014-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-014-es' },


        /** Surf **/

        { key: 'short-board', label: 'Short-Board', sport: 'tablas-de-surf-es' },
        { key: 'longboard', label: 'Longboard', sport: 'tablas-de-surf-es' },
        { key: 'minimalibu', label: 'Minimalibu', sport: 'tablas-de-surf-es' },
        { key: 'softboard', label: 'Softboard', sport: 'tablas-de-surf-es' },
        { key: 'fish', label: 'Fish', sport: 'tablas-de-surf-es' },
        { key: 'funboard', label: 'Funboard', sport: 'tablas-de-surf-es' },
        { key: 'otros', label: 'Otros', sport: 'tablas-de-surf-es' },

        { key: 'invierno-43-54', label: 'Invierno (4/3, 5/4)', sport: 'neoprenos-015-es' },
        { key: 'verano-32-22', label: 'Verano (3/2, 2/2)', sport: 'neoprenos-015-es' },
        { key: 'lycra', label: 'Lycra', sport: 'neoprenos-015-es' },
        { key: 'escarpines', label: 'Escarpines', sport: 'neoprenos-015-es' },
        { key: 'guantes', label: 'Guantes', sport: 'neoprenos-015-es' },
        { key: 'otros', label: 'Otros', sport: 'neoprenos-015-es' },

        { key: 'leash', label: 'Leash', sport: 'accesorios-015-es' },
        { key: 'wax', label: 'Wax', sport: 'accesorios-015-es' },
        { key: 'bagsshockscovers', label: 'Bags/Shocks/Covers', sport: 'accesorios-015-es' },
        { key: 'padsgrips', label: 'Pads/grips', sport: 'accesorios-015-es' },
        { key: 'fins', label: 'Fins', sport: 'accesorios-015-es' },
        { key: 'poncho', label: 'Poncho', sport: 'accesorios-015-es' },
        { key: 'otros', label: 'Otros', sport: 'accesorios-015-es' },

        { key: 'camaras-de-accion', label: 'Camaras de Accion', sport: 'electronica-015-es' },
        { key: 'relojes', label: 'Relojes', sport: 'electronica-015-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'electronica-015-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-015-es' },


        /** Windsurf **/

        { key: 'wave-board', label: 'Wave Board', sport: 'tablas-016-es' },
        { key: 'freestyle', label: 'Freestyle', sport: 'tablas-016-es' },
        { key: 'freeride', label: 'Freeride', sport: 'tablas-016-es' },
        { key: 'freerace', label: 'Freerace', sport: 'tablas-016-es' },
        { key: 'slalom', label: 'Slalom', sport: 'tablas-016-es' },
        { key: 'windsup', label: 'WindSUP', sport: 'tablas-016-es' },
        { key: 'windfoil', label: 'WindFoil', sport: 'tablas-016-es' },
        { key: 'otros', label: 'Otros', sport: 'tablas-016-es' },

        { key: 'wave-board', label: 'Wave Board', sport: 'velas-016-es' },
        { key: 'freestyle', label: 'Freestyle', sport: 'velas-016-es' },
        { key: 'freeride', label: 'Freeride', sport: 'velas-016-es' },
        { key: 'freerace', label: 'Freerace', sport: 'velas-016-es' },
        { key: 'slalom', label: 'Slalom', sport: 'velas-016-es' },
        { key: 'windsup', label: 'WindSUP', sport: 'velas-016-es' },
        { key: 'windfoil', label: 'WindFoil', sport: 'velas-016-es' },
        { key: 'aparejos', label: 'Aparejos', sport: 'velas-016-es' },
        { key: 'otros', label: 'Otros', sport: 'velas-016-es' },

        { key: 'mastiles-y-pies-de-mastiles', label: 'Mastiles y pies de mastiles', sport: 'equipamiento-016-es' },
        { key: 'arneses-y-cabos', label: 'Arneses y cabos', sport: 'equipamiento-016-es' },
        { key: 'botavaras', label: 'Botavaras', sport: 'equipamiento-016-es' },
        { key: 'otros', label: 'Otros', sport: 'equipamiento-016-es' },

        { key: 'invierno-43-54', label: 'Invierno (4/3, 5/4)', sport: 'neoprenos-016-es' },
        { key: 'verano-32-22-shorty', label: 'Verano (3/2, 2/2, shorty)', sport: 'neoprenos-016-es' },
        { key: 'lycra', label: 'Lycra', sport: 'neoprenos-016-es' },
        { key: 'capuchas-escarpines-y-guantes', label: 'Capuchas, Escarpines y guantes', sport: 'neoprenos-016-es' },
        { key: 'otros', label: 'Otros', sport: 'neoprenos-016-es' },

        { key: 'cascos', label: 'Cascos', sport: 'accesorios-016-es' },
        { key: 'aletas', label: 'Aletas', sport: 'accesorios-016-es' },
        { key: 'alargadores', label: 'Alargadores', sport: 'accesorios-016-es' },
        { key: 'fundas', label: 'Fundas', sport: 'accesorios-016-es' },
        { key: 'chalecos', label: 'Chalecos', sport: 'accesorios-016-es' },
        { key: 'poncho', label: 'Poncho', sport: 'accesorios-016-es' },
        { key: 'otros', label: 'Otros', sport: 'accesorios-016-es' },

        { key: 'camaras-de-accion', label: 'Camaras de Accion', sport: 'electronica-016-es' },
        { key: 'relojes', label: 'Relojes', sport: 'electronica-016-es' },
        { key: 'otros', label: 'Otros', sport: 'electronica-016-es' },


        /** Tech **/

        { key: 'auriculares', label: 'Auriculares', sport: 'tech-017-es' },
        { key: 'camaras-de-accion', label: 'Camaras de accion', sport: 'tech-017-es' },
        { key: 'cargadores-y-baterias', label: 'Cargadores y Baterias', sport: 'tech-017-es' },
        { key: 'ciclocomputadores', label: 'Ciclocomputadores', sport: 'tech-017-es' },
        { key: 'cronometros', label: 'Cronometros', sport: 'tech-017-es' },
        { key: 'gps-de-montana', label: 'GPS de Montana', sport: 'tech-017-es' },
        { key: 'pulseras-de-actividad', label: 'Pulseras de Actividad', sport: 'tech-017-es' },
        { key: 'pistola-masaje-muscular', label: 'Pistola masaje muscular', sport: 'tech-017-es' },
        { key: 'recambios', label: 'Recambios', sport: 'tech-017-es' },
        { key: 'relojes', label: 'Relojes', sport: 'tech-017-es' },
        { key: 'sensores', label: 'Sensores', sport: 'tech-017-es' },
        { key: 'otros', label: 'Otros', sport: 'tech-017-es' },


        /** Otros deportes **/

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'alpinismo-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'alpinismo-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'alpinismo-018-es' },
        { key: 'otros', label: 'Otros', sport: 'alpinismo-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'atletismo-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'atletismo-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'atletismo-018-es' },
        { key: 'otros', label: 'Otros', sport: 'atletismo-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'baloncesto-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'baloncesto-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'baloncesto-018-es' },
        { key: 'otros', label: 'Otros', sport: 'baloncesto-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'balonmano-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'balonmano-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'balonmano-018-es' },
        { key: 'otros', label: 'Otros', sport: 'balonmano-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'beisbol-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'beisbol-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'beisbol-018-es' },
        { key: 'otros', label: 'Otros', sport: 'beisbol-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'bodyboard-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'bodyboard-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'bodyboard-018-es' },
        { key: 'otros', label: 'Otros', sport: 'bodyboard-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'calistenia-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'calistenia-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'calistenia-018-es' },
        { key: 'otros', label: 'Otros', sport: 'calistenia-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'camping-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'camping-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'camping-018-es' },
        { key: 'otros', label: 'Otros', sport: 'camping-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'danza-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'danza-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'danza-018-es' },
        { key: 'otros', label: 'Otros', sport: 'danza-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'dardos-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'dardos-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'dardos-018-es' },
        { key: 'otros', label: 'Otros', sport: 'dardos-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'equitacion-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'equitacion-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'equitacion-018-es' },
        { key: 'otros', label: 'Otros', sport: 'equitacion-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'esgrima-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'esgrima-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'esgrima-018-es' },
        { key: 'otros', label: 'Otros', sport: 'esgrima-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'futbol-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'futbol-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'futbol-018-es' },
        { key: 'otros', label: 'Otros', sport: 'futbol-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'futbol-americano-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'futbol-americano-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'futbol-americano-018-es' },
        { key: 'otros', label: 'Otros', sport: 'futbol-americano-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'futbol-sala-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'futbol-sala-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'futbol-sala-018-es' },
        { key: 'otros', label: 'Otros', sport: 'futbol-sala-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'gimnasia-deportiva-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'gimnasia-deportiva-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'gimnasia-deportiva-018-es' },
        { key: 'otros', label: 'Otros', sport: 'gimnasia-deportiva-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'hockey-hierba-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'hockey-hierba-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'hockey-hierba-018-es' },
        { key: 'otros', label: 'Otros', sport: 'hockey-hierba-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'hockey-hielo-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'hockey-hielo-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'hockey-hielo-018-es' },
        { key: 'otros', label: 'Otros', sport: 'hockey-hielo-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'natacion-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'natacion-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'natacion-018-es' },
        { key: 'otros', label: 'Otros', sport: 'natacion-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'patinaje-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'patinaje-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'patinaje-018-es' },
        { key: 'otros', label: 'Otros', sport: 'patinaje-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'pilates-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'pilates-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'pilates-018-es' },
        { key: 'otros', label: 'Otros', sport: 'pilates-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'rugby-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'rugby-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'rugby-018-es' },
        { key: 'otros', label: 'Otros', sport: 'rugby-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'skate-y-longboard-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'skate-y-longboard-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'skate-y-longboard-018-es' },
        { key: 'otros', label: 'Otros', sport: 'skate-y-longboard-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'voleibol-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'voleibol-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'voleibol-018-es' },
        { key: 'otros', label: 'Otros', sport: 'voleibol-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'voley-playa-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'voley-playa-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'voley-playa-018-es' },
        { key: 'otros', label: 'Otros', sport: 'voley-playa-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'wakeboard-y-esqui-acuatico-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'wakeboard-y-esqui-acuatico-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'wakeboard-y-esqui-acuatico-018-es' },
        { key: 'otros', label: 'Otros', sport: 'wakeboard-y-esqui-acuatico-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'waterpolo-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'waterpolo-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'waterpolo-018-es' },
        { key: 'otros', label: 'Otros', sport: 'waterpolo-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'yoga-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'yoga-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'yoga-018-es' },
        { key: 'otros', label: 'Otros', sport: 'yoga-018-es' },

        { key: 'equipamiento-y-accesorios', label: 'Equipamiento y accesorios', sport: 'otros-018-es' },
        { key: 'ropa', label: 'Ropa', sport: 'otros-018-es' },
        { key: 'calzado', label: 'Calzado', sport: 'otros-018-es' },
        { key: 'otros', label: 'Otros', sport: 'otros-018-es' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Precio',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 10000,
      step: 5,
    },
  },
  {
    id: 'useState',
    label: 'Estado',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_useState'],
    config: {
      options: [{
          key: 'labelNew',
          label: 'Nuevo con etiqueta',
        },
        {
          key: 'noLabelNew',
          label: 'Nuevo sin etiqueta',
        },
        {
          key: 'veryGood',
          label: 'Muy bueno',
        },
        {
          key: 'good',
          label: 'Bueno',
        },
        {
          key: 'acceptable',
          label: 'Aceptable',
        },
      ],
    }
  },
  {
    id: 'color',
    label: 'Color',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_color'],
    config: {
      options: [{
        key: 'yellow',
        label: 'Amarillo',
      }, {
        key: 'blue',
        label: 'Azul',
      }, {
        key: 'white',
        label: 'Blanco',
      }, {
        key: 'gray',
        label: 'Gris',
      }, {
        key: 'brown',
        label: 'Marron',
      }, {
        key: 'purple',
        label: 'Morado',
      }, {
        key: 'multicolored',
        label: 'Multicolor',
      }, {
        key: 'orange',
        label: 'Naranja',
      }, {
        key: 'black',
        label: 'Negro',
      }, {
        key: 'green',
        label: 'Verde',
      }, {
        key: 'red',
        label: 'Rojo',
      }, {
        key: 'pink',
        label: 'Rosa',
      }, ],
    }
  },
  {
    id: 'gender',
    label: 'Género',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_gender'],
    config: {
      options: [{
        key: 'man',
        label: 'Hombre',
      }, {
        key: 'woman',
        label: 'Mujer',
      }, {
        key: 'boy',
        label: 'Niño',
      }, {
        key: 'girl',
        label: 'Niña',
      }, {
        key: 'unisex',
        label: 'Unisex',
      }, ],
    }
  },
  {
    id: 'size',
    label: 'Talla',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_size'],
    config: {
      options: [
        { key: 'aletas-y-monoaletas', label: 'Aletas y monoaletas', disabled: true, className: 'optionTitle' },
        { key: 'calzado', label: 'Calzado', disabled: true, className: 'optionTitle' },
        { key: '23', label: '23' },
        { key: '24', label: '24' },
        { key: '25', label: '25' },
        { key: '26', label: '26' },
        { key: '27', label: '27' },
        { key: '28', label: '28' },
        { key: '29', label: '29' },
        { key: '30', label: '30' },
        { key: '31', label: '31' },
        { key: '32', label: '32' },
        { key: '33', label: '33' },
        { key: '34', label: '34' },
        { key: '35', label: '35' },
        { key: '36', label: '36' },
        { key: '37', label: '37' },
        { key: '38', label: '38' },
        { key: '39', label: '39' },
        { key: '40', label: '40' },
        { key: '41', label: '41' },
        { key: '42', label: '42' },
        { key: '43', label: '43' },
        { key: '44', label: '44' },
        { key: '45', label: '45' },
        { key: '46', label: '46' },
        { key: '47', label: '47' },
        { key: '48', label: '48' },

        { key: 'bastones-de-esqui', label: 'Bastones de Esqui', disabled: true, className: 'optionTitle' },
        { key: 'pj0fd8x6yh-95cm', label: '95cm' },
        { key: 'pj0fd8x6yh-100cm', label: '100cm' },
        { key: 'pj0fd8x6yh-105cm', label: '105cm' },
        { key: 'pj0fd8x6yh-110cm', label: '110cm' },
        { key: 'pj0fd8x6yh-115cm', label: '115cm' },
        { key: 'pj0fd8x6yh-120cm', label: '120cm' },
        { key: 'pj0fd8x6yh-125cm', label: '125cm' },
        { key: 'pj0fd8x6yh-130cm', label: '130cm' },
        { key: 'pj0fd8x6yh-135cm', label: '135cm' },
        { key: 'pj0fd8x6yh-140cm', label: '140cm' },

        { key: 'bicicletas', label: 'Bicicletas', disabled: true, className: 'optionTitle' },
        { key: 'cuadro-bicicleta', label: 'Cuadro bicicleta', disabled: true, className: 'optionTitle' },
        { key: 'xs47-49155cm-160cm', label: 'XS/47-49/155cm-160cm' },
        { key: 's49-53160cm-170cm', label: 'S/49-53/160cm-170cm' },
        { key: 'm53-57170cm-180cm', label: 'M/53-57/170cm-180cm' },
        { key: 'l57-61180cm-190cm', label: 'L/57-61/180cm-190cm' },
        { key: 'xl61190cm', label: 'XL/+61/+190cm' },

        { key: 'esqui-snowboard', label: 'Esqui, Snowboard', disabled: true, className: 'optionTitle' },
        { key: '75-80cm', label: '75-80cm' },
        { key: '80-85cm', label: '80-85cm' },
        { key: '85-90cm', label: '85-90cm' },
        { key: '90-95cm', label: '90-95cm' },
        { key: '95-100cm', label: '95-100cm' },
        { key: '100-105cm', label: '100-105cm' },
        { key: '105-110cm', label: '105-110cm' },
        { key: '110-115cm', label: '110-115cm' },
        { key: '115-120cm', label: '115-120cm' },
        { key: '120-125cm', label: '120-125cm' },
        { key: '125-130cm', label: '125-130cm' },
        { key: '130-135cm', label: '130-135cm' },
        { key: '135-140cm', label: '135-140cm' },
        { key: '140-145cm', label: '140-145cm' },
        { key: '145-150cm', label: '145-150cm' },
        { key: '150-155cm', label: '150-155cm' },
        { key: '155-160cm', label: '155-160cm' },
        { key: '160-165cm', label: '160-165cm' },
        { key: '165-170cm', label: '165-170cm' },
        { key: '170-175cm', label: '170-175cm' },
        { key: '175-180cm', label: '175-180cm' },
        { key: '180-185cm', label: '180-185cm' },
        { key: '185-190cm', label: '185-190cm' },
        { key: '190-195cm', label: '190-195cm' },
        { key: '195-200cm', label: '195-200cm' },

        { key: 'fijaciones-esquisnowboard', label: 'Fijaciones ESQUI/SNOWBOARD', disabled: true, className: 'optionTitle' },
        { key: '3btgeh8wog-xs', label: 'XS' },
        { key: '3btgeh8wog-s', label: 'S' },
        { key: '3btgeh8wog-m', label: 'M' },
        { key: '3btgeh8wog-l', label: 'L' },
        { key: '3btgeh8wog-xl', label: 'XL' },

        { key: 'guantes-de-boxeo-mitones', label: 'Guantes de boxeo, mitones', disabled: true, className: 'optionTitle' },
        { key: '6oz42kg', label: '6OZ/42kg' },
        { key: '8oz42kg-46kg', label: '8OZ/42kg-46kg' },
        { key: '10oz46kg-55kg', label: '10OZ/46kg-55kg' },
        { key: '12oz55kg-64kg', label: '12OZ/55kg-64kg' },
        { key: '14oz-64kg-73kg', label: '14OZ (64kg-73kg)' },
        { key: '16oz73kg-82kg', label: '16OZ/73kg-82kg' },
        { key: '18oz82kg-91kg', label: '18OZ/82kg-91kg' },
        { key: '20oz91kg', label: '20OZ/+91kg' },

        { key: 'neopreno', label: 'Neopreno', disabled: true, className: 'optionTitle' },
        { key: 'ropa-cascos-y-protecciones', label: 'Ropa, Cascos y protecciones', disabled: true, className: 'optionTitle' },
        { key: '2dqm8nxo93-xs', label: 'XS' },
        { key: '2dqm8nxo93-s', label: 'S' },
        { key: '2dqm8nxo93-ms', label: 'MS' },
        { key: '2dqm8nxo93-m', label: 'M' },
        { key: '2dqm8nxo93-mt', label: 'MT' },
        { key: '2dqm8nxo93-ls', label: 'LS' },
        { key: '2dqm8nxo93-l', label: 'L' },
        { key: '2dqm8nxo93-xl', label: 'XL' },
        { key: '2dqm8nxo93-xxl', label: 'XXL' },

        { key: 'ruedas-bicicleta-carretera', label: 'Ruedas Bicicleta carretera', disabled: true, className: 'optionTitle' },
        { key: '8EcAge7Okm-650c', label: '650c' },
        { key: '8EcAge7Okm-700c', label: '700c' },
        { key: '8EcAge7Okm-26', label: "26''" },
        { key: '8EcAge7Okm-275', label: "27.5''" },
        { key: '8EcAge7Okm-275-plus', label: "27.5'' plus" },
        { key: '8EcAge7Okm-29', label: "29''" },
        { key: '8EcAge7Okm-29-plus', label: "29'' plus" },
        { key: '8EcAge7Okm-fatbike', label: 'fatbike' },

        { key: 'tablas-de-surf-medida', label: 'Tablas de Surf (medida)', disabled: true, className: 'optionTitle' },
        { key: 'ufjrp2dbyr-5-6', label: "5''-6''" },
        { key: 'ufjrp2dbyr-6-7', label: "6''-7''" },
        { key: 'ufjrp2dbyr-7-8', label: "7'-8''" },
        { key: 'ufjrp2dbyr-8-9', label: "8''-9''" },
        { key: 'ufjrp2dbyr-9', label: "+9''" },

        { key: 'tablas-de-surf-volumen', label: 'Tablas de Surf (volumen)', disabled: true, className: 'optionTitle' },
        { key: '14l-16l', label: '14l-16l' },
        { key: '17-19l', label: '17-19l' },
        { key: '20-22l', label: '20-22l' },
        { key: '23-25l', label: '23-25l' },
        { key: '26-28l', label: '26-28l' },
        { key: '29-31l', label: '29-31l' },
        { key: '32-34l', label: '32-34l' },
        { key: '35-37l', label: '35-37l' },
        { key: '38-40l', label: '38-40l' },
        { key: '41-43l', label: '41-43l' },
        { key: '44-46l', label: '44-46l' },
        { key: '47-49l', label: '47-49l' },
        { key: '50-52l', label: '50-52l' },
        { key: '53-55l', label: '53-55l' },
        { key: '56-58l', label: '56-58l' },
        { key: '59-61l', label: '59-61l' },
        { key: '61l', label: '+61l' },

        { key: 'kimonos', label: 'Kimonos', disabled: true, className: 'optionTitle' },
        { key: '100cm', label: '100cm' },
        { key: '110cm', label: '110cm' },
        { key: '120cm', label: '120cm' },
        { key: '130cm', label: '130cm' },
        { key: '140cm', label: '140cm' },
        { key: '150cm', label: '150cm' },
        { key: '160cm', label: '160cm' },
        { key: '170cm', label: '170cm' },
        { key: '180cm', label: '180cm' },
        { key: '190cm', label: '190cm' },
        { key: '200cm', label: '200cm' },
        { key: '205cm', label: '205cm' },

        { key: 'cometas-kite', label: 'Cometas kite', disabled: true, className: 'optionTitle' },
        { key: '9-10m', label: '9-10m' },
        { key: '11-12m', label: '11-12m' },
        { key: '12-14m', label: '12-14m' },
        { key: '14-16m', label: '14-16m' },
        { key: '16-18m', label: '16-18m' },
        { key: '18-20m', label: '18-20m' },
        { key: '20-22m', label: '20-22m' },

        { key: 'botas-esqui', label: 'Botas Esqui', disabled: true, className: 'optionTitle' },
        { key: '22cm', label: '22cm' },
        { key: '22-5cm', label: '22,5cm' },
        { key: '23cm', label: '23cm' },
        { key: '23-5cm', label: '23,5cm' },
        { key: '24cm', label: '24cm' },
        { key: '24-5cm', label: '24,5cm' },
        { key: '25cm', label: '25cm' },
        { key: '25-5cm', label: '25,5cm' },
        { key: '26cm', label: '26cm' },
        { key: '26-5cm', label: '26,5cm' },
        { key: '27cm', label: '27cm' },
        { key: '27-5cm', label: '27,5cm' },
        { key: '28cm', label: '28cm' },
        { key: '28-5cm', label: '28,5cm' },
        { key: '29cm', label: '29cm' },
        { key: '29-5cm', label: '29,5cm' },
        { key: '30cm', label: '30cm' },
        { key: '30-5cm', label: '30,5cm' },
        { key: '31cm', label: '31cm' },
        { key: '31-5cm', label: '31,5cm' },

        { key: 'velas-windsurf', label: 'Velas windsurf', disabled: true, className: 'optionTitle' },
        { key: '2-0', label: '2,0' },
        { key: '2-1', label: '2,1' },
        { key: '2-2', label: '2,2' },
        { key: '2-3', label: '2,3' },
        { key: '2-4', label: '2,4' },
        { key: '2-5', label: '2,5' },
        { key: '2-6', label: '2,6' },
        { key: '2-7', label: '2,7' },
        { key: '2-8', label: '2,8' },
        { key: '2-9', label: '2,9' },
        { key: '3-0', label: '3,0' },
        { key: '3-1', label: '3,1' },
        { key: '3-2', label: '3,2' },
        { key: '3-3', label: '3,3' },
        { key: '3-4', label: '3,4' },
        { key: '3-5', label: '3,5' },
        { key: '3-6', label: '3,6' },
        { key: '3-7', label: '3,7' },
        { key: '3-8', label: '3,8' },
        { key: '3-9', label: '3,9' },
        { key: '4-0', label: '4,0' },
        { key: '4-1', label: '4,1' },
        { key: '4-2', label: '4,2' },
        { key: '4-3', label: '4,3' },
        { key: '4-4', label: '4,4' },
        { key: '4-5', label: '4,5' },
        { key: '4-6', label: '4,6' },
        { key: '4-7', label: '4,7' },
        { key: '4-8', label: '4,8' },
        { key: '4-9', label: '4,9' },
        { key: '5-0', label: '5,0' },
        { key: '5-1', label: '5,1' },
        { key: '5-2', label: '5,2' },
        { key: '5-3', label: '5,3' },
        { key: '5-4', label: '5,4' },
        { key: '5-5', label: '5,5' },
        { key: '5-6', label: '5,6' },
        { key: '5-7', label: '5,7' },
        { key: '5-8', label: '5,8' },
        { key: '5-9', label: '5,9' },
        { key: '6-0', label: '6,0' },
        { key: '6-1', label: '6,1' },
        { key: '6-2', label: '6,2' },
        { key: '6-3', label: '6,3' },
        { key: '6-4', label: '6,4' },
        { key: '6-5', label: '6,5' },
        { key: '6-6', label: '6,6' },
        { key: '6-7', label: '6,7' },
        { key: '6-8', label: '6,8' },
        { key: '6-9', label: '6,9' },
        { key: '7-0', label: '7,0' },
        { key: '7-1', label: '7,1' },
        { key: '7-2', label: '7,2' },
        { key: '7-3', label: '7,3' },
        { key: '7-4', label: '7,4' },
        { key: '7-5', label: '7,5' },
      ],
    }
  },
  {
    id: 'address',
    label: 'Ubicación',
    type: 'LocationFilter',
    group: 'primary',
    queryParamNames: ['address'],
    config: {},
  },
  {
    id: 'userAccountType',
    label: 'Tipo vendedor',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_userAccountType'],
    config: {
      options: [
        { key: USER_ACCOUNT_PERSONAL, label: 'Particular' },
        { key: USER_ACCOUNT_PROFESSIONAL, label: 'Profesional' },
      ]
    }
  }
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Más reciente' },
    { key: '-createdAt', label: 'Menos reciente' },
    { key: '-price', label: 'Precios más bajos' },
    { key: 'price', label: 'Precios más altos' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevancia', longLabel: 'Relevancia (Búsqueda por palabra clave)' },
  ],
};
