import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SectionShareIcons.module.css'
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

const SectionShareIcons = props => {
  const {
    className,
    url,
  } = props;

  const bgStyle = {'fill':'#c4e560'}
  const iconColor = '#ffffff';
  const iconSize = 36;

  return (
    <div className={className}>
      <span className={css.label}>
        <FormattedMessage id="SectionShareIcons.label" />
      </span>
      <WhatsappShareButton url={url} className={css.button}>
        <WhatsappIcon size={iconSize} iconFillColor={iconColor} bgStyle={bgStyle} round />
      </WhatsappShareButton>
      <FacebookShareButton url={url} className={css.button}>
        <FacebookIcon size={iconSize} iconFillColor={iconColor} bgStyle={bgStyle} round />
      </FacebookShareButton>
      <EmailShareButton url={url} className={css.button}>
        <EmailIcon size={iconSize} iconFillColor={iconColor} bgStyle={bgStyle} round />
      </EmailShareButton>
    </div>
  );
}

export default SectionShareIcons;
