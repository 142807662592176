import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionGoodPracticeHero.module.css';

const SectionGoodPracticeHero = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionGoodPracticeHero.titleP1" />
          <br />
          <FormattedMessage id="SectionGoodPracticeHero.titleP2" />
        </h1>
        <div className={css.heroDescription}>
          <p><FormattedMessage id="SectionGoodPracticeHero.description" /></p>
        </div>
      </div>
    </div>
  );
};

SectionGoodPracticeHero.defaultProps = { rootClassName: null, className: null };

SectionGoodPracticeHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionGoodPracticeHero;
