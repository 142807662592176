import { tokenBeseif, misTransaccionesBeseif } from '../../util/api';
import { types as sdkTypes } from '../../util/sdkLoader';
  
export const CODIGO_REQUEST = 'app/ListingPage/CODIGO_REQUEST';


const { UUID } = sdkTypes;

// ================ Reducer ================ //

const initialState = {
  codigo: ''
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CODIGO_REQUEST:
      return { ...state, codigo: payload.codigo, showListingError: null };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const codigoRequest = codigo => ({
  type: CODIGO_REQUEST,
  payload: { codigo },
});

// ================ Thunks ================ //


export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const a = sdk.currentUser
    .show()
    .then(response => {
      // console.log(response)
      const data = response.data.data.attributes
      tokenBeseif({
        "client_id": "Burby",
        "client_secret": process.env.REACT_APP_TOKEN_BASEIF,
        "grant_type": "client_credentials"
      }).then((resp)=>{
        misTransaccionesBeseif(resp, {
          "email_usuario": data.email,
        }).then((b)=>{
          dispatch( codigoRequest(b.codigo) )
        })
      })
    })

 return a;
};