import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';
import chevronRight from './chevron-right-blue.svg';
import css from './SectionSupercategories.module.css';

import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const supercategoryLink = (icon, name, searchQuery) => {
  const iconImg = <img src={icon} className={css.icon}/>
  const nameText = <span className={css.scName}>{name.toUpperCase()}</span>;
  const chevronRightImg = <img src={chevronRight} className={css.chevronRight}/>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.link}>
      <div className={css.card}>
        {iconImg}{nameText}{chevronRightImg}
      </div>
    </NamedLink>
  );
};

const SectionSupercategories = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const supercategoryOptions = findOptionsForSelectFilter('sport', config.custom.filters);

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionSupercategories.title" />
      </h2>
      <ul className={css.supercategories}>
        {supercategoryOptions.map(sc => (
          <li className={css.supercategory} key={sc.key}>
            {supercategoryLink(sc.icon, sc.label, '?pub_sport=' + sc.key)}
          </li>
        ))}
      </ul>
      <p className={css.seoText}>
        <FormattedMessage id="SectionSupercategories.seoText" />
      </p>
    </div>
  );
};

SectionSupercategories.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionSupercategories.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSupercategories;
