import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '../../components';

import css from './SectionBestPractices.module.css';

const SectionBestPractices = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div>
      <h2 className={css.title}>
        <FormattedMessage id="SectionBestPractices.title" />
      </h2>

      <div className={css.content}>
        <FormattedMessage id="SectionBestPractices.content" />
      </div>

      <div className={css.seeMoreLink}>
        <NamedLink name="GoodPracticeGuidePage">
          <FormattedMessage id="SectionBestPractices.seeMoreLink" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionBestPractices.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionBestPractices.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionBestPractices;
