import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Form } from '../../components';
import KeywordAutocompleteInput from '../../components/KeywordAutocompleteInput/KeywordAutocompleteInput';

import css from './TopbarSearchForm.module.css';

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      fetchingPlaceDetails: false,
    };
  }
  onSubmit(values) {
    this.props.onSubmit({ keywords: values.keywords });
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            handleSubmit,
          } = formRenderProps;

          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot
          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <Field
                name="keywords"
                render={({ input, meta }) => {
                  return (
                    <>
                      <KeywordAutocompleteInput
                        className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                        iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                        inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                        placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                        input={input}
                        meta={meta}
                        searchInput={this.searchInput}
                      />
                    </>
                  );
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
