import React from 'react';
import professionalBadgeImage from '../../assets/professional-badge.svg';
import PropTypes from 'prop-types';
import {intlShape} from '../../util/reactIntl';

import css from './IconProfessionalBadge.module.css';

const IconProfessionalBadge = props => {
  const { className, imgClassName, intl } = props;

  return (
    <div className={className || css.professionalBadge}>
      <img className={imgClassName || css.professionalBadgeImg} src={professionalBadgeImage} alt={
        intl.messages['IconProfessionalBadge.professionalBagdeAlt']
      } />
    </div>
  );
};

const { string } = PropTypes;

IconProfessionalBadge.defaultProps = {
  className: null,
  imgClassName: null,
};

IconProfessionalBadge.propTypes = {
  className: string,
  imgClassName: string,
  intl: intlShape.isRequired,
};

export default IconProfessionalBadge;
