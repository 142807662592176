import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import * as validatorsBurby from '../../util/validatorsBurby';
import {
  ExternalLink,
  FieldTextInput,
  FieldSelect,
  FieldCheckboxBoolean,
  Form,
  PrimaryButton,
} from '../../components';

import {
  USER_ACCOUNT_PERSONAL,
  USER_ACCOUNT_PROFESSIONAL,
} from '../../util/constantsBurby';

import showPasswordIcon from './showPasswordIcon.svg';
import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        values,
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // terms checkbox
      const termsRequiredMessage = intl.formatMessage({
        id: 'SignupForm.termsRequired',
      });
      const termsRequired = validatorsBurby.requiredCheckbox(termsRequiredMessage);

      // show password
      const showPasswordLabel = intl.formatMessage({
        id: 'SignupForm.showPasswordLabel',
      });

      // instagram
      const instagramLabel = intl.formatMessage({
        id: 'SignupForm.instagramLabel',
      });
      const instagramPlaceholder = intl.formatMessage({
        id: 'SignupForm.instagramPlaceholder',
      });

      // account type
      const atData = {
        label: intl.formatMessage({
          id: 'SignupForm.accountTypeLabel',
        }),
        options: {
          none: {
            value: '',
            label: intl.formatMessage({
              id: 'SignupForm.noAccountType',
            })
          },
          personal: {
            value: USER_ACCOUNT_PERSONAL,
            label: intl.formatMessage({
              id: 'SignupForm.personalAccount',
            })
          },
          professional: {
            value: USER_ACCOUNT_PROFESSIONAL,
            label: intl.formatMessage({
              id: 'SignupForm.professionalAccount',
            })
          },
        },
      }

      const atRequiredMessage = intl.formatMessage({
        id: 'SignupForm.accountTypeRequired',
      });
      const atRequired = validators.required(atRequiredMessage);
      const atOptions = [];

      for(const opt in atData.options){
        atOptions.push(
          <option key={opt} value={atData.options[opt].value}>
            {atData.options[opt].label}
          </option>
        );
      }

      // idDocument
      const idDocumentLabel = intl.formatMessage({
        id: 'SignupForm.idDocumentLabel',
      });
      const idDocumentPlaceholder = intl.formatMessage({
        id: 'SignupForm.idDocumentPlaceholder',
      });
      const idDocumentRequiredMessage = intl.formatMessage({
        id: 'SignupForm.idDocumentRequiredMessage',
      });
      const idDocumentRequired = validators.required(idDocumentRequiredMessage);

      const renderIdDocumentFieldTextInput = () => {
        let output = '';

        if(values.accountType === 'professional'){
          output = (
            <div className="professional">
              <FieldTextInput
                className={css.idDocument}
                type="text"
                id={formId ? `${formId}.idDocument` : 'idDocument'}
                name="idDocument"
                autoComplete="off"
                label={idDocumentLabel}
                placeholder={idDocumentPlaceholder}
                validate={idDocumentRequired}
              />
            </div>
          )
        }

        return output; 
      }

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const legalAdviceLink = (
        <ExternalLink
          className={css.termsLink}
          href="aviso-legal"
          target="_blank"
        >
          <FormattedMessage id="SignupForm.legalAdviceLinkText" />
        </ExternalLink>
      );

      const cookiesPolicyLink = (
        <ExternalLink
          className={css.termsLink}
          href="politica-de-cookies"
          target="_blank"
        >
          <FormattedMessage id="SignupForm.cookiesPolicyLinkText" />
        </ExternalLink>
      );

      const [showPassword, setShowPassword] = useState(false);
      const handleChangeShowPwd = () => showPassword ? setShowPassword(false) : setShowPassword(true);
      const inputType = showPassword ? 'text' : 'password';

      /* Hide paswword on submit */
      const handleSubmitExtended = (values) => {
        setShowPassword(false);
        handleSubmit(values);
      }

      return (
        <Form className={classes} onSubmit={handleSubmitExtended}>
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            <div className={css.passwordContainer}>
              <FieldTextInput
                className={css.password}
                type={inputType}
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordValidators}
              />
              <div className={css.showPasswordContainer}>
                <input
                  className={css.showPassword}
                  id={formId ? `${formId}.showPassword` : 'showPassword'}
                  type="checkbox"
                  onChange={handleChangeShowPwd}
                  aria-label={showPasswordLabel}
                />
                <img
                  className={css.showPasswordIcon}
                  src={showPasswordIcon} alt={showPasswordLabel}
                />
              </div>
            </div>
          </div>
          <FieldTextInput
            className={css.instagram}
            type="text"
            id={formId ? `${formId}.instagram` : 'instagram'}
            name="instagram"
            autoComplete="nickname"
            label={instagramLabel}
            placeholder={instagramPlaceholder}
          />
          <FieldSelect
             className={css.accounType}
             id={formId ? `${formId}.accountType` : 'accountType'}
             name="accountType"
             autoComplete="off"
             label={atData.label}
             validate={atRequired}
          >
            {atOptions}
          </FieldSelect>
          {renderIdDocumentFieldTextInput()}
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FieldCheckboxBoolean
                  id={formId ? `${formId}.termsCheckbox` : 'termsCheckbox'}
                  textClassName={css.termsText}
                  name="termsCheckbox"
                  label={
                    <FormattedMessage
                      id="SignupForm.termsAcceptText"
                      values={{ legalAdviceLink }}
                    />
                  }
                  validate={termsRequired}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenLegalAdvice: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
