/**
 * Validator functions and helpers for Final Forms
 */

// Final Form expects and undefined value for a successful validation
const VALID = undefined;

// To be used with Checkboxes that are required to be checked
export const requiredCheckbox = message => value => {
  if (value === true) {
    return VALID;
  }
  return message;
};
