import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ListingCard } from '../../components';
import { NamedLink } from '../../components';
import css from './SectionLatestProducts.module.css';

const SectionLatestProducts = props => {
  const { rootClassName, className, latestListings } = props;
  const classes = classNames(rootClassName || css.root, className);

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionLatestProducts.title" />
      </h2>

      <ul className={css.products}>
        {latestListings.map(l => (
          <li key={l.id.uuid}>
            <ListingCard
              className={css.product}
              listing={l}
              renderSizes={cardRenderSizes}
            />
          </li>
        ))}
      </ul>

      <div className={css.seeMoreLink}>
        <NamedLink name="SearchPage">
          <FormattedMessage id="SectionLatestProducts.seeMoreLink" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionLatestProducts.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLatestProducts.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLatestProducts;
