import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const GET_LATEST_LISTINGS_SUCCESS = 'app/SearchPage/GET_LATEST_LISTINGS_SUCCESS';
export const GET_LATEST_LISTINGS_ERROR = 'app/SearchPage/GET_LATEST_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  getLatestListingsError: null,
  latestListingsIds: [],
};

const resultIds = data => data.data.map(l => l.id);
// TODO: to filter only the necessary attributes of data.data

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LATEST_LISTINGS_SUCCESS:
      return {
        ...state,
        getLatestListingsError: null,
        latestListingsIds: resultIds(payload.data),
      }
    case GET_LATEST_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
         ...state,
         getLatestListingsError: payload,
       };
     default:
      return state;
  }
}

export default landingPageReducer;

// ================ Action creators ================ //

export const getLatestListingsSuccess = response => ({
  type: GET_LATEST_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const getLatestListingsError = e => ({
  type: GET_LATEST_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const getLatestListings = () => (dispatch, getState, sdk) => {
  return sdk.listings
    .query({
      state: 'published',
      perPage: 6,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'limit.images': 1,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(getLatestListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(getLatestListingsError(storableError(e)));
      throw e;
    });
} 

export const loadData = (params, search) => {
  return getLatestListings();
};
