import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { Form, Button, FieldSelect, FieldTextInput } from '../../components';

// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './EditListingSpecificationsForm.module.css';

export const EditListingSpecificationsFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        useStateOptions,
        sizeOptions,
        colorOptions,
        genderOptions,
      } = formRenderProps;

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingSpecificationsForm.updateFailed" />
        </p>
      ) : null;

      // useState
      const useStateLabel = intl.formatMessage({
        id: "EditListingSpecificationsForm.useStateLabel",
      });
      const useStateRequired = required(
        intl.formatMessage({
          id: "EditListingSpecificationsForm.useStateRequired",
        })
      );
      const useStatePlaceholder = intl.formatMessage({
        id: "EditListingSpecificationsForm.useStatePlaceholder",
      });
      // size
      const sizeLabel = intl.formatMessage({
        id: "EditListingSpecificationsForm.sizeLabel",
      });
      const sizeRequired = required(
        intl.formatMessage({
          id: "EditListingSpecificationsForm.sizeRequired",
        })
      );
      const sizePlaceholder = intl.formatMessage({
        id: "EditListingSpecificationsForm.sizePlaceholder",
      });
      // color
      const colorLabel = intl.formatMessage({
        id: "EditListingSpecificationsForm.colorLabel",
      });
      const colorRequired = required(
        intl.formatMessage({
          id: "EditListingSpecificationsForm.colorRequired",
        })
      );
      const colorPlaceholder = intl.formatMessage({
        id: "EditListingSpecificationsForm.colorPlaceholder",
      });
      // gender
      const genderLabel = intl.formatMessage({
        id: "EditListingSpecificationsForm.genderLabel",
      });
      const genderRequired = required(
        intl.formatMessage({
          id: "EditListingSpecificationsForm.genderRequired",
        })
      );
      const genderPlaceholder = intl.formatMessage({
        id: "EditListingSpecificationsForm.genderPlaceholder",
      });
      // description
      const descriptionLabel = intl.formatMessage({
        id: "EditListingSpecificationsForm.descriptionLabel",
      });
      const descriptionRequired = required(
        intl.formatMessage({
          id: "EditListingSpecificationsForm.descriptionRequired",
        })
      );
      const descriptionPlaceholder = intl.formatMessage({
        id: "EditListingSpecificationsForm.descriptionPlaceholder",
      });
      // brand
      const brandLabel = intl.formatMessage({
        id: "EditListingSpecificationsForm.brandLabel",
      });
      const brandRequired = required(
        intl.formatMessage({
          id: "EditListingSpecificationsForm.brandRequired",
        })
      );
      const brandPlaceholder = intl.formatMessage({
        id: "EditListingSpecificationsForm.brandPlaceholder",
      });

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}

          <FieldSelect
            className={css.useState}
            name="useState"
            id="useState"
            label={useStateLabel}
            validate={useStateRequired}
          >
            <option value="">{useStatePlaceholder}</option>
            {useStateOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            className={css.size}
            name="size"
            id="size"
            label={sizeLabel}
          >
            <option value="">{sizePlaceholder}</option>
            {sizeOptions.map(c => (
              <option
                key={c.key}
                value={c.key}
                disabled={c.hasOwnProperty('disabled') && c.disabled}
              >
                {c.label}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            className={css.color}
            name="color"
            id="color"
            label={colorLabel}
          >
            <option value="">{colorPlaceholder}</option>
            {colorOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            className={css.gender}
            name="gender"
            id="gender"
            label={genderLabel}
          >
            <option value="">{genderPlaceholder}</option>
            {genderOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>

          <FieldTextInput
            id="brand"
            name="brand"
            className={css.brand}
            type="textarea"
            label={brandLabel}
            placeholder={brandPlaceholder}
          />
          
          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionLabel}
            placeholder={descriptionPlaceholder}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingSpecificationsFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingSpecificationsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  useStateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default compose(injectIntl)(EditListingSpecificationsFormComponent);
