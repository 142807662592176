import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './LegalAdvice.module.css';

const LegalAdvice = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Última actualización: 30 de Octubre de 2021.</p>

      <p>
        El presente documento tiene como finalidad el establecer y regular las normas de uso de este
        portal web, entendiendo por éste todas las páginas y sus contenidos propiedad de Fernando Alonso Velazquez  y de Mª Laura Leiros Sardo (en adelante, BURBY) a las cuales se accede a través del dominio: <b>https://www.burby.es</b>
      </p>
      <p>
        La utilización del portal web atribuye la condición de usuario del mismo e implica la
        aceptación de todas las condiciones incluidas en el presente Aviso Legal. El usuario se
        compromete a leer atentamente el presente Aviso Legal en cada una de las ocasiones en que
        se proponga utilizar nuestro portal web ya que éste y sus condiciones de uso recogidas en el
        presente Aviso Legal pueden sufrir modificaciones.
      </p>

      <h2>Quiénes somos</h2>
      <p>
        Somos BURBY, plataforma digital que pone en contacto a personas deportistas para que compren
        y vendan material deportivo de segunda mano.
      </p>
      <p>
        En Burby queremos potenciar la práctica del deporte, facilitando los recursos necesarios y
        fomentando el reciclaje de material deportivo.
      </p>
      <p>
        Nuestro principal compromiso es potenciar la economía circular un modelo de producción y
        consumo que implica compartir, alquilar, reutilizar, reparar, renovar y reciclar materiales
        y productos existentes todas las veces que sea posible para crear un valor añadido en el
        ámbito deportivo.
      </p>

      <h2>Marco Normativo:</h2>
      <p>
        La actividad de esta web se encuentra sujeta al marco legal español y europeo,
        concretamente a las siguientes normas:
      </p>
      <p>
        Reglamento General de Protección de Datos (RGPD) (UE) 2016/679, de 27 de abril, que
        regula el tratamiento de datos personales en los países de la Unión Europea.
      </p>
      <p>
        Ley Orgánica 3/2018, de 5 de diciembre, sobre Protección de Datos Personales y Garantía de
        los Derechos Digitales (LOPD y GDD), norma de ámbito regional (aplicable a España), y que
        define y amplía muchos de los conceptos y derechos presentes en el RGPD.
      </p>
      <p>
        Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio
        Electrónico (LSSICE), norma que afecta a aquellas webs que, de alguna forma, realicen
        actividades económicas mediante medios electrónicos, como es el caso de esta web.
      </p>

      <h2>Datos Identificativos:</h2>
      <p>
        Este sitio web es propiedad de Fernando Alonso Velazquez, residente en Zurich (Suiza),  y de Mª Laura Leiros Sardo, residente en Majadahonda, (Madrid - España).
      </p>

      <h2>CLÁUSULAS AVISO LEGAL BURBY</h2>

      <h3>1. USUARIOS:</h3>
      <p>
        El acceso y/o uso de este portal web atribuye la condición de USUARIO, que acepta, desde
        dicho acceso y/o uso, las Condiciones Generales de Uso aquí reflejadas. Las citadas
        Condiciones serán de aplicación independientemente de las Condiciones Generales de
        Contratación que en su caso resulten de obligado cumplimiento.
      </p>

      <h3>2. USO DEL PORTAL:</h3>
      <p>
        BURBY proporciona el acceso a diversas informaciones en relación a sus servicios,
        productos, información de nuestra entidad, apartados de contacto, hiperenlaces a redes
        sociales, (en adelante, “los contenidos”) pertenecientes a BURBY.
      </p>
      <p>
        El usuario asume la responsabilidad del uso del portal y sus funcionalidades. Dicha
        responsabilidad se extiende al registro, recogida de datos o cumplimentación de formularios
        que fuese necesaria para acceder a determinados servicios. En dicho registro el usuario
        será responsable de aportar información veraz y lícita.
      </p>
      <p>Más información acerca del uso del portal:</p>
      <div className={css.websiteUseInfoLinks}>
      <NamedLink name="BuyersGuidePage" className={css.link} >
        Guía para compradores
      </NamedLink>
      <NamedLink name="SellersGuidePage" className={css.link} >
        Guía para vendedores
      </NamedLink>
      </div>

      <h3>3. PROPIEDAD INTELECTUAL E USO INDUSTRIAL:</h3>
      <p>
        BURBY por sí o como cesionaria, es titular de todos los derechos de propiedad intelectual
        e industrial de su página web, así como de los elementos contenidos en la misma (imágenes,
        sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores,
        estructura y diseño, selección de materiales usados, programas de ordenador necesarios
        para su funcionamiento, acceso y uso, etc.).
      </p>
      <p>
        En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de
        Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y
        la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad
        o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte
        y por cualquier medio técnico, sin la autorización de BURBY.
      </p>
      <p>
        El usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial
        titularidad de BURBY. La utilización no autorizada de la información contenida en este
        Sitio, así como los perjuicios ocasionados en los derechos de propiedad intelectual e
        industrial de BURBY pueden dar lugar al ejercicio de las acciones que legalmente
        correspondan y, si procede, a las responsabilidades que de dicho ejercicio se deriven.
      </p>

      <h3>4. EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD:</h3>
      <p>
        BURBY no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier
        naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones en los
        contenidos, falta de disponibilidad del portal o la transmisión de virus o programas
        maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas
        tecnológicas necesarias para evitarlo.
      </p>
      <p>
        El contenido, información y/o consejos expresados en este portal web y blog deben
        entenderse como simplemente orientativos, quedando exenta de cualquier responsabilidad
        con los usuarios que haga uso de ellos, ya que son éstas las que deberán decidir
        según su criterio la oportunidad de los mismos.
      </p>
      <p>
        BURBY se reserva el derecho de modificar el contenido del Sitio sin previo aviso y sin
        ningún tipo de limitación. Así mismo la empresa declina cualquier responsabilidad por
        los eventuales daños y perjuicios que puedan ocasionarse por la falta de disponibilidad
        y/o continuidad de este Sitio y de los servicios que se ofrecen en él. Así mismo,
        tampoco podemos garantizar la ausencia de virus ni de otros elementos en la Web que
        puedan producir alteraciones en su sistema informático.
      </p>
      <p>
        Si Ud. cree que cualquier contenido y/o información de este Sitio vulnera un derecho
        legítimo o la legalidad vigente le agradeceríamos que se pusiera en contacto con BURBY
        para que podamos tomar las medidas oportunas.
      </p>

      <h3>5. MODIFICACIONES:</h3>
      <p>
        BURBY se reserva el derecho de efectuar sin previo aviso las modificaciones que
        considere oportunas en su portal, pudiendo cambiar, suprimir o añadir tanto los
        contenidos y servicios que se presten a través de la misma como la forma en la que
        éstos aparezcan presentados o localizados en su portal.
      </p>

      <h3>6. DERECHO DE EXCLUSIÓN:</h3>
      <p>
        BURBY se reserva el derecho a denegar o retirar el acceso a portal y/o los servicios
        ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos
        usuarios que incumplan las presentes Condiciones Generales de Uso.
      </p>

      <h3>7. GENERALIDADES:</h3>
      <p>
        BURBY perseguirá el incumplimiento de las presentes condiciones así como cualquier
        utilización indebida de su portal ejerciendo todas las acciones civiles y penales
        que le puedan corresponder en derecho.
      </p>

      <h3>8. LEGISLACIÓN APLICABLE:</h3>
      <p>
        La relación entre BURBY el distribuidor y el usuario final se regirá por la
        normativa española vigente y cualquier controversia se someterá a los Juzgados y
        tribunales de la ciudad de Madrid.
      </p>

      <h3>9. POLÍTICA DE PROTECCIÓN DE DATOS:</h3>
      <p>
        Información adicional y detallada sobre el uso de sus datos de carácter personal.
      </p>

      <h4>9.1 ¿Quién es el responsable del tratamiento de sus datos?</h4>
      <ul>
        <li>
          Identidad: Fernando Alonso Velazquez, residente en Zurich (Suiza),  y de Mª Laura Leiros Sardo, residente en Majadahonda (Madrid - España).
        </li>
        <li>
          Correo electrónico: hola@burby.es
        </li>
      </ul>

      <h4>9.2 ¿Con qué finalidad tratamos sus datos personales?</h4>
      <p>
        Tratamos los datos personales que nos facilite con las siguientes finalidades:
      </p>
      <ol>
        <li>
          Cumplir con el deber de información y obtención de consentimientos requeridos por la
          nueva normativa europea de protección de datos de carácter personal.
        </li>
        <li>
          La gestión de la relación con el usuario (comprador y vendedor). El suministro de los
          datos con este fin es obligado, impidiéndole en otro caso el cumplimiento del contrato.
        </li>
        <li>
          El envío de comunicaciones, artículos de opinión o noticias, circulares informativas
          redactadas por nuestros departamentos y relacionadas con nuestra actividad, salvo que
          usted manifieste su voluntad en contra por cualquier medio.
        </li>
      </ol>

      <h4>9.3 ¿Qué datos tratamos?</h4>
      <p>
        En el marco de la prestación de los servicios que ofrecemos y condicionado a las
        finalidades consentidas por usted, nuestra entidad únicamente se tratarán los datos
        estrictamente necesarios para la correcta gestión de la relación contractual y/o prestación
        de los servicios, y será exclusivamente los que usted nos haya facilitado.
      </p>

      <h4>9.4 ¿Durante cuánto tiempo trataremos sus datos?</h4>
      <p>
        Los datos personales que nos proporcione el usuario serán conservados mientras se mantenga
        la relación contractual / mercantil vigente, o bien durante un plazo de cinco años a partir
        de la última relación negocial siguiendo la normativa a efectos fiscales. No obstante nuestra
        entidad seguirá conservando su información para el envío de posibles comunicaciones comerciales
        que sean del interés del usuario, mientras no se solicite su supresión por el interesado. El
        usuario siempre podrá ejercitar los derechos que le reconoce la normativa vigente poniéndose
        en contacto a través de la vía que le sea más cómoda, tal y como explicamos más abajo.
      </p>

      <h4>9.5 ¿A qué destinatarios se comunicarán sus datos?</h4>
      <p>
        No se comunicarán sus datos a ningún tercero no relacionado con la prestación del servicio,
        salvo obligaciones legales vigentes, y la propia relación de comunicación que se produzca
        entre los compradores y vendedores interesados en llevar a cabo una relación de compra-venta
        de un producto publicado en nuestra plataforma.
      </p>
      <p>
        Le informamos que, para la correcta prestación de los servicios es posible que distintos
        prestadores de servicios contratados por nuestra entidad (encargados del tratamiento) puedan
        tener acceso a la información personal necesaria para realizar sus funciones.
      </p>
      <p>
        Estos servicios prestados por terceros son necesarios para el desarrollo de nuestra
        actividad y la prestación del servicio, y en todo momento, el tratamiento de los datos que
        lleven a cabo se rige por un contrato que vincula al encargado respecto a nuestra entidad.
        En ningún caso utilizarán la información para otros fines y la tratarán de conformidad con
        las directrices estipuladas por nuestra entidad, acordes a nuestra política de privacidad
        y a la normativa vigente en materia de protección de datos.
      </p>
      <p>
        Nuestra entidad en su compromiso con la privacidad y protección de datos del Usuario,
        elegirá únicamente prestadores de servicio que ofrezcan las garantías suficientes para
        aplicar medidas técnicas y organizativas apropiadas, de manera que el tratamiento sea
        conforme la legislación aplicable en materia de protección de datos y garanticen la
        protección de los derechos del Usuario.
      </p>

      <h4>9.6 ¿Cuáles son sus derechos cuando nos facilita sus datos?</h4>
      <p>
        Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando datos
        personales que les concierne o no. En este sentido, usted tiene derecho a solicitar:
      </p>
      <ul>
        <li>
          <b>Acceso:</b> El interesado tendrá derecho a obtener del responsable del tratamiento
          confirmación de si se están tratando o no los datos que le concierne, así como
          información detallada acerca de determinados aspectos del tratamiento que se esté
          llevando a cabo.
        </li>
        <li>
          <b>Rectificación:</b> El interesado tendrá derecho a obtener la rectificación de los datos
          personales inexactos que le concierne o que se completen aquellos que fueran incompletos.
        </li>
        <li>
          <b>Supresión:</b> El interesado tendrá derecho a solicitar la supresión de sus datos personales,
          en todo caso la supresión estará sujeta a los límites establecidos en la norma reguladora.
        </li>
        <li>
          <b>Limitación de su tratamiento:</b> El interesado tendrá derecho a solicitar la limitación
          respecto al tratamiento de sus datos de carácter personal.
        </li>
        <li>
          <b>Oposición al tratamiento:</b> En determinadas circunstancias  y por motivos relacionados
          con su situación particular, los interesados podrán oponerse al tratamiento de sus
          datos. La entidad dejará de tratar los datos, salvo por motivos legítimos, imperiosos,
          o el ejercicio o la defensa de posibles reclamaciones.
        </li>
        <li>
          <b>Derecho a la portabilidad de sus datos:</b> Es decir, tendrá derecho a recibir los datos
          personales que le incumban, que haya facilitado a un responsable del tratamiento, en
          un formato estructurado, de uso común y lectura mecánica y a transmitirlos a otro
          responsable del tratamiento.
        </li>
      </ul>
      <p>
        Podrá ejercer los referidos derechos, dirigiéndose al responsable. Para ello, puede
        utilizar los datos de contacto facilitados al inicio del presente documento. Si desea
        obtener información adicional respecto al ejercicio de sus derechos, también puede
        dirigirse a la Agencia Española de Protección de Datos.
      </p>
      <p>
        Asimismo le informamos que, si lo cree oportuno, tiene el derecho a retirar, en cualquier
        momento, el consentimiento otorgado para alguna finalidad específica, sin que ello afecte
        a la licitud del tratamiento, basado en el consentimiento previo a su retirada.
      </p>
      <p>
        En caso que entendiera que no se han atendido adecuadamente sus derechos, podrá presentar
        una reclamación ante la Agencia Española de Protección de Datos. C/ Jorge Juan, 6.
        28001 – Madrid. www.agpd.es.
      </p>

      <h4>9.7 Ejercicio de sus derechos</h4>
      <p>El usuario puede enviar un escrito a Burby, a la dirección indicada en el encabezado de la presente Política, o bien por medio de un correo electrónico a la dirección hola@burby.es, adjuntando fotocopia de su documento de identidad, en cualquier momento y de manera gratuita, para:</p>
      <ul>
        <li>Solicitar la supresión de sus datos (incluye datos personales y listado de productos publicados) cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</li>
        <li>Revocar los consentimientos otorgados.</li>
        <li>Obtener confirmación acerca de si en Burby se están tratando datos personales que conciernen al Usuario o no.</li>
        <li>Acceder a sus datos personales.</li>
        <li>Rectificar los datos inexactos o incompletos.</li>
      </ul>
      <p>Si desea obtener información adicional respecto al ejercicio de sus derechos, también puede dirigirse a la Agencia Española de Protección de Datos.</p>
      <p>Asimismo le informamos que, si lo cree oportuno, tiene el derecho a retirar, en cualquier momento, el consentimiento otorgado para alguna finalidad específica, sin que ello afecte a la licitud del tratamiento, basado en el consentimiento previo a su retirada.</p>
      <p>En caso que entendiera que no se han atendido adecuadamente sus derechos, podrá presentar una reclamación ante la Agencia Española de Protección de Datos. C/ Jorge Juan, 6. 28001 – Madrid. www.agpd.es.</p>

      <h4>9.8 Nuestras medidas de seguridad</h4>
      <p>
        BURBY ha adoptado las medidas de índole técnico y organizativo necesarias y acordes al
        nivel de riesgo del tratamiento de los datos y su impacto en los derechos de las personas
        para garantizar la seguridad del tratamiento de datos de carácter personal y que son y sean
        exigidas en cada momento por la legislación aplicable. Sin embargo, el usuario debe ser
        consciente de que en la actualidad las medidas de seguridad en Internet no son inexpugnables
        y por lo tanto no se está a salvo de posibles intromisiones ilegales e indebidas, que no
        serían responsabilidad de BURBY, ya que en todo momento se ha actuado con la diligencia
        debida para proteger los datos conforme determina la Ley.
      </p>
      <h3>10. Pasarela de pago Beseif:</h3>
      <p>Este Sitio Web incluye determinados servicios prestados por BESEIF SOLUTIONS, S.L. que permiten agilizar y aportar seguridad al proceso de compra y envío de los artículos que los usuarios decidan adquirir/vender. Concretamente, los servicios prestados se incluyen en los botones de “Comprar” y “Mis transacciones” de este Sitio Web.</p>
      <p>El pago y el envío de los artículos de este Sitio Web se puede realizar mediante la plataforma “BESEIF”, ofrecida por la compañía de nacionalidad española BESEIF SOLUTIONS, S.L., (en adelante BESEIF) domiciliada en San Sebastián (Gipuzkoa), con C.I.F nº B75167189.</p>
      <p>En este sentido, los usuarios consienten que, cuando decidan adquirir/vender un artículo en este Sitio Web haciendo uso de los servicios de BESEIF, sus datos identificativos y de contacto se transmitan automáticamente a dicha entidad para evitar que el usuario tenga que incluir manualmente todos los datos necesarios para gestionar el envío y BESEIF pueda agilizar todo el proceso, mejorando así la experiencia de compraventa del usuario. Puede consultar los Términos y Condiciones de Uso así como Política de Privacidad y Cookies de BESEIF en <a href='https://beseif.com' target="_blanc">www.beseif.com</a>.</p>
      <p></p>

    </div>
  );
};

LegalAdvice.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

LegalAdvice.propTypes = {
  rootClassName: string,
  className: string,
};

export default LegalAdvice;
