import React from 'react';
import classNames from 'classnames';

import IconHourGlass from '../../components/LocationAutocompleteInput/IconHourGlass';
// import { IconSpinner } from '../../components';

import css from '../../components/LocationAutocompleteInput/LocationAutocompleteInput.module.css';

const KeywordAutocompleteInput = props => {
  const {
    autoFocus,
    rootClassName,
    className,
    iconClassName,
    inputClassName,
    validClassName,
    placeholder,
    input,
    meta,
    searchInput,
  } = props;

  const { touched, valid } = meta || {};
  const isValid = valid && touched;

  const rootClass = classNames(rootClassName || css.root, className);
  const iconClass = classNames(iconClassName || css.icon);
  const inputClass = classNames(inputClassName || css.input, { [validClassName]: isValid });
  const { name} = input;

  return (
    <div className={rootClass}>
      <div className={iconClass}>
        <IconHourGlass />
      </div>
      <input
        className={inputClass}
        id="keyword-search"
        ref={searchInput}
        type="text"
        {...input}
        placeholder={placeholder}
        autoComplete="off"

        autoFocus={autoFocus}
        name={name}
      />
    </div>
  );
};

export default KeywordAutocompleteInput;