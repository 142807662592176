import React, { useState } from 'react';
import { SecondaryButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal } from '../../components';

import css from './SendMessageForm.module.css';

const SaleButtonMaybe = props => {
  const { rootClassName, onConfirmedSale, children} = props;

  const handleClick = () => {
    onConfirmedSale();
    setEnquiryModalOpen(false);
    setStateListing('closed');
  }

  const isAuthenticated = true
  const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);
  const [stateListing, setStateListing] = useState(props.stateListing);
  return (
    <>
      {stateListing !== 'closed' &&
      <>
        <SecondaryButton
          rootClassName={css.setListingSold}
          onClick={ () => setEnquiryModalOpen(true) }
        >
          {children}
          <FormattedMessage id="SendMessageForm.setListingSold" />
        </SecondaryButton>
        <Modal
          id="ListingPage.enquiry"
          contentClassName={css.enquiryModalContent}
          isOpen={isAuthenticated && enquiryModalOpen}
          onClose={ () => setEnquiryModalOpen(false) }
          usePortal
          onManageDisableScrolling={ ()=> {} }
        >
          <h1 className={css.setListingSoldModalTitle}><FormattedMessage id="SendMessageForm.setListingSoldModalTitle" /></h1>
          <SecondaryButton
            rootClassName={css.setListingSoldCancel}
            onClick={ () => setEnquiryModalOpen(false) }
          >
            Cancelar
          </SecondaryButton>

          <SecondaryButton
            rootClassName={css.setListingSoldConfirm}
            onClick={ ()=> handleClick() }
          >
            Aceptar
          </SecondaryButton>
        </Modal>
      </>
      }
    </>
  );
}

export default SaleButtonMaybe;
