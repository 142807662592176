import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.module.css';

const CustomSportSelectFieldMaybe = props => {
  const { name, id, sports, intl, handleCategoryChange } = props;
  const sportLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.sportLabel',
  });
  const sportPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.sportPlaceholder',
  });
  const sportRequired = required(
    intl.formatMessage({
      id: 'EditListingDescriptionForm.sportRequired',
    })
  );
  return sports ? (
    <FieldSelect
      className={css.category}
      name={name}
      id={id}
      label={sportLabel}
      validate={sportRequired}
      handleinputchange={handleCategoryChange}
    >
      <option disabled value="">
        {sportPlaceholder}
      </option>
      {sports.map(s => (
        <option key={s.key} value={s.key}>
          {s.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomSportSelectFieldMaybe;
