import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import css from './SectionGoodPracticeItem.module.css';

const SectionGoodPracticeItem = props => {
  const { rootClassName, className, itemData } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={className}>
      <h2 className={css.title}>
        {itemData.number}. {itemData.title}
      </h2>
      <div className={css.seeMoreLink}>
        {itemData.content}
      </div>
    </div>
  );
};

SectionGoodPracticeItem.defaultProps = { className: null };

const { string, object } = PropTypes;

SectionGoodPracticeItem.propTypes = {
  className: string,
  item: object,
};

export default SectionGoodPracticeItem;
