import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaLinkedin.module.css';

const IconSocialMediaLinkedin = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="17px" height="17px" 
      viewBox="0 0 17 17"
    >
      <path
        d="M 3.957031 5.714844 L 3.957031 16.460938 L 0.375 16.460938 L 0.375 5.714844 Z M 4.183594 2.394531 C 4.191406 2.921875 4.007812 3.363281 3.636719 3.714844 C 3.265625 4.070312 2.773438 4.25 2.164062 4.25 L 2.144531 4.25 C 1.550781 4.25 1.074219 4.070312 0.710938 3.714844 C 0.351562 3.363281 0.171875 2.921875 0.171875 2.394531 C 0.171875 1.859375 0.355469 1.417969 0.730469 1.0625 C 1.101562 0.714844 1.585938 0.539062 2.1875 0.539062 C 2.789062 0.539062 3.269531 0.714844 3.628906 1.0625 C 3.992188 1.417969 4.175781 1.859375 4.183594 2.394531 Z M 16.828125 10.300781 L 16.828125 16.460938 L 13.261719 16.460938 L 13.261719 10.714844 C 13.261719 9.953125 13.117188 9.359375 12.824219 8.929688 C 12.53125 8.5 12.074219 8.28125 11.453125 8.28125 C 10.996094 8.28125 10.613281 8.40625 10.308594 8.65625 C 10 8.90625 9.769531 9.214844 9.617188 9.585938 C 9.539062 9.800781 9.5 10.09375 9.5 10.464844 L 9.5 16.460938 L 5.929688 16.460938 C 5.945312 13.578125 5.953125 11.238281 5.953125 9.445312 C 5.953125 7.648438 5.949219 6.582031 5.941406 6.234375 L 5.929688 5.714844 L 9.5 5.714844 L 9.5 7.277344 L 9.476562 7.277344 C 9.621094 7.042969 9.769531 6.84375 9.921875 6.667969 C 10.074219 6.496094 10.277344 6.308594 10.535156 6.105469 C 10.792969 5.902344 11.105469 5.746094 11.476562 5.632812 C 11.851562 5.519531 12.265625 5.464844 12.722656 5.464844 C 13.957031 5.464844 14.953125 5.875 15.703125 6.695312 C 16.453125 7.515625 16.828125 8.714844 16.828125 10.300781 Z M 16.828125 10.300781"
        fillRule="evenodd"
      />
  </svg>
  );
};

IconSocialMediaLinkedin.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaLinkedin.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkedin;
